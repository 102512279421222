import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import Select from "react-select";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./OrderTracking.css";
import SalesOrderNew from "../SalesNew/SalesOrderNew";
import DeliveryNote from "../SalesNew/DeliveryNote";
import QuotationNew from "../SalesNew/QuotationNew";

const OrderTracking = ({
  customerName,
  customerId,
  batchNo,
  handleCancle,
  navigation,
}) => {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");
  const [activePopup, setActivePopup] = useState(null);
  const [showTrackOrder, setShowTrackOrder] = useState(true);
  const [showSalesOrder, setShowSalesOrder] = useState(false);
  const [showDeliveryNote, setShowDelibveryNote] = useState(false);
  const [showQuotation, setShowQuotation] = useState(false);
  const [showQuotationDetails, setShowQuotationDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [custName, setCustName] = useState("");
  const [custId, setCustId] = useState("");
  const [enqId, setEnqId] = useState("");
  const [showFollowUpModal, setShowFollowUpModal] = useState(false);
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [isMessageSubmitted, setIsMessageSubmitted] = useState(false);
  const [stepStatuses, setStepStatuses] = useState([]);

  const [selectedDate, setSelectedDate] = useState(null); // Add state for DatePicker
  const [purchaseOrderFiles, setPurchaseOrderFiles] = useState([]);

  const [completedSteps, setCompletedSteps] = useState([]);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [followUpMessages, setFollowUpMessages] = useState([]);

  const handleStepCompletion = (currentStepIndex) => {
    // Always progress to the next step, but only if not already at the last step
    if (currentStepIndex < steps.length - 1) {
      setActiveStepIndex(currentStepIndex + 1);
    }

    // Mark the step as completed
    if (!completedSteps.includes(currentStepIndex)) {
      setCompletedSteps((prev) => [...prev, currentStepIndex]);
    }

    if (currentStepIndex === 2) {
      setLoading(true);
      fetch(BASE_URL + `/sceduleFollowUp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          uniqueId: decryptedUniqueId,
          inquiryId: batchNo,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseCode === "200") {
            setIsMessageSubmitted(false); // Hide the message icon button
            setShowFollowUpModal(false); // Hide the follow-up modal
            setLoading(false);
          } else {
            console.error(
              "Failed to schedule follow-up:",
              data.responseMessage
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error scheduling follow-up:", error);
          setLoading(false);
        });
    }
  };

  const [messageTitle, setMessageTitle] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [newValidationMessage, setValidationMessage] = useState("");

  const validateForm = () => {
    let newValidationMessage = "";

    if (!messageTitle.trim()) {
      newValidationMessage += "Title is required";
    } else if (!messageContent.trim()) {
      newValidationMessage += "Message content is required";
    } else if (!selectedDate) {
      newValidationMessage += "Please select a follow-up date";
    }

    setValidationMessage(newValidationMessage);
    return newValidationMessage === "";
  };

  const insertFollowupMessage = () => {
    console.log("Props check:", { customerId, batchNo });

    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    const followupData = {
      uniqueId: decryptedUniqueId,
      inquiryId: batchNo,
      title: messageTitle,
      description: messageContent,
      date: selectedDate.toISOString(),
    };
    setLoading(true);
    fetch(BASE_URL + `/insertFollowup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(followupData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          fetchFollwupDetails();
          setIsMessageSubmitted(true);
          setLoading(false);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
        setLoading(false);
      });
  };

  const insertPurchaseOrderFile = (file) => {
    const formData = new FormData();
    const purchaseOrderData = {
      uniqueId: decryptedUniqueId,
      inquiryId: batchNo,
    };
    formData.append("requestBody", JSON.stringify(purchaseOrderData));
    formData.append("files", file);
    setLoading(true);
    fetch(BASE_URL + `/insertPurchaseOrderFile`, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setLoading(false);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
        setLoading(false);
      });
  };

  const getFollowup = () => {
    setLoading(true);
    fetch(BASE_URL + `/getFollowupByInquiryId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        inquiryId: batchNo,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setFollowUpMessages(data.data);
          setLoading(false);
        } else {
          console.error("Failed to fetch Followup:", data.responseMessage);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
        setLoading(false);
      });
  };

  const getPurchaseOrderFiles = () => {
    setLoading(true);
    fetch(BASE_URL + `/getPurchaseOrderFileNameByInquiryId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        inquiryId: batchNo,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setPurchaseOrderFiles(data.fileNames);
          setLoading(false);
        } else {
          console.error("Failed to fetch Followup:", data.responseMessage);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
        setLoading(false);
      });
  };

  const fetchFollwupDetails = () => {
    setLoading(true);
    fetch(BASE_URL + `/fetchFollowUpDetailsByInquiryId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, inquiryId: batchNo }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const statusData = data.data;
          const statuses = [
            statusData.inquiryStatus,
            statusData.quotationStatus,
            statusData.followupStatus,
            statusData.purchaseOrderStatus,
            statusData.salesOrderStatus,
            statusData.deliveryNoteCheckStatus,
            statusData.dispatchStatus,
            statusData.paymentStatus,
            statusData.damageStatus,
          ];

          // Set the step statuses
          setStepStatuses(statuses);

          // Calculate the active step (next one after the last "completed" step)
          const lastCompletedStepIndex = statuses.findIndex(
            (status) => status !== "Completed"
          );
          setActiveStepIndex(
            lastCompletedStepIndex === -1
              ? statuses.length - 1
              : lastCompletedStepIndex
          );
          setLoading(false);
        } else {
          console.error(
            "Failed to fetch Follow-up details:",
            data.responseMessage
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFollwupDetails();
    getFollowup();
    getPurchaseOrderFiles();
  }, []);

  const handleNavigation = (key) => {
    if (navigation?.state?.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleCancleClick = () => {
    handleCancle();
  };

  const steps = [
    {
      title: "Enquiry",
      icon: "info-circle",
      tooltip: "Step to collect customer enquiry",
    },
    {
      title: "Quotation",
      icon: "file-alt",
      tooltip: "Provide a detailed quotation",
    },
    {
      title: "Follow-Up",
      icon: "phone",
      tooltip: "Reach out for confirmation or feedback",
    },
    {
      title: "Customer Purchase Order",
      icon: "shopping-cart",
      tooltip: "Confirm and place the purchase order",
    },
    {
      title: "Sales Order",
      icon: "file-invoice",
      tooltip: "Generate the sales order",
    },
    {
      title: "Delivery Note",
      icon: "warehouse",
      tooltip: "Record and track delivered goods accurately",
    },
    {
      title: "Dispatch",
      icon: "truck",
      tooltip: "Send out the order for delivery",
    },
    {
      title: "Breakage/Damage",
      icon: "exclamation-triangle",
      tooltip: "Handle any issues of breakage or damage",
    },
    {
      title: "Payment",
      icon: "money-check-alt",
      tooltip: "Receive the payment for the order",
    },
  ];

  const dispatchOptions = [
    { value: "dispatch", label: "Dispatch" },
    { value: "in_transport", label: "In Transport" },
    { value: "delivered", label: "Delivered" },
    { value: "not_delivered", label: "Not Delivered" },
  ];

  const customDispatchStyles = {
    control: (base) => ({
      ...base,
      minHeight: 35,
      minWidth: 200,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  const handleCancleSalesOrder = () => {
    setShowTrackOrder(true);
    setShowSalesOrder(false);
    setShowDelibveryNote(false);
    setShowQuotation(false);
    setShowQuotationDetails(false);
  };

  const handleCancleDeliveryNote = () => {
    setShowTrackOrder(true);
    setShowSalesOrder(false);
    setShowDelibveryNote(false);
    setShowQuotation(false);
    setShowQuotationDetails(false);
  };

  const handleCancelQuotation = () => {
    setShowTrackOrder(true);
    setShowSalesOrder(false);
    setShowDelibveryNote(false);
    setShowQuotation(false);
    setShowQuotationDetails(false);
  };

  const handleCancelQuotationDetails = () => {
    setShowTrackOrder(true);
    setShowSalesOrder(false);
    setShowDelibveryNote(false);
    setShowQuotation(false);
    setShowQuotationDetails(false);
  };

  // Modify the FilePopup component:

  const FileUploadStep = ({
    step,
    index,
    activeStepIndex,
    onStepComplete,
    completedSteps,
  }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedDispatchStatus, setSelectedDispatchStatus] = useState("");
    const circleRef = React.useRef(null);

    const handleCircleClick = (index, stepTitle) => {
      if (stepTitle === "Customer Purchase Order") {
        const circleElement = document.getElementById(`step-circle-${index}`);
        if (circleElement) {
          const rect = circleElement.getBoundingClientRect();
          setActivePopup({
            index,
            position: {
              top: rect.top + window.scrollY,
              left: rect.left + window.scrollX,
              width: rect.width,
              height: rect.height,
            },
          });
        }
      }

      if (stepTitle === "Sales Order") {
        if (customerId || customerName || batchNo) {
          setShowTrackOrder(false);
          setShowDelibveryNote(false);
          setShowSalesOrder(true);
          setCustId(customerId);
          setCustName(customerName);
          setEnqId(batchNo);
        }
      }

      if (stepTitle === "Delivery Note") {
        if (customerId || customerName || batchNo) {
          setShowTrackOrder(false);
          setShowDelibveryNote(true);
          setShowQuotation(false);
          setShowSalesOrder(false);
          setCustId(customerId);
          setCustName(customerName);
          setEnqId(batchNo);
        }
      }

      if (stepTitle === "Quotation") {
        if (stepStatuses[1] != "Completed") {
          if (customerId || customerName || batchNo) {
            setShowTrackOrder(false);
            setShowDelibveryNote(false);
            setShowQuotation(true);
            setShowSalesOrder(false);
            setCustId(customerId);
            setCustName(customerName);
            setEnqId(batchNo);
          }
        }
      }

      if (stepTitle === "Follow-Up") {
        setShowMessagesModal(true);
        getFollowup(); // Fetch follow-up messages when the modal is opened
      }
    };

    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      setSelectedFile(file);
    };

    // To this:
    const handleDispatchStatusChange = (selectedOption) => {
      setSelectedDispatchStatus(selectedOption);
    };
    const handleFileSubmit = () => {
      if (step.title === "Customer Purchase Order" && selectedFile) {
        // Send the file to the API
        insertPurchaseOrderFile(selectedFile);
        // Complete the step
        onStepComplete(index);
        // Clear the selected file after submission
        setSelectedFile(null);
      } else if (step.title === "Dispatch" && selectedDispatchStatus) {
        // Make API call when submit button is clicked for dispatch status
        fetch(BASE_URL + `/insertDispatchstatus`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({
            inquiryId: batchNo,
            uniqueId: decryptedUniqueId,
            message: selectedDispatchStatus.label,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.responseCode === "200") {
              onStepComplete(index);
              setSelectedDispatchStatus("");
            } else {
              console.error(
                "Failed to update dispatch status:",
                data.responseMessage
              );
            }
          })
          .catch((error) => {
            console.error("Error updating dispatch status:", error);
          });
      }
    };

    const getCircleClassName = () => {
      const stepStatus = stepStatuses[index];
      if (stepStatus === "Completed") return "completed"; // Green
      if (index === activeStepIndex) return "active"; // Blue
      return "";
    };

    const renderStepContent = () => {
      // Handle Dispatch step
      if (step.title === "Dispatch") {
        return (
          <div className="d-flex flex-column align-items-center">
            <Select
              className="mt-0"
              options={dispatchOptions}
              onChange={handleDispatchStatusChange}
              placeholder="Select Status"
              styles={customDispatchStyles}
              hideSelectedOptions
              isClearable
              value={selectedDispatchStatus}
            />
            {selectedDispatchStatus && (
              <button
                className="btn btn-xs active mx-2 mt-3"
                onClick={handleFileSubmit}
              >
                Submit
              </button>
            )}
          </div>
        );
      }

      if (step.title === "Follow-Up") {
        return (
          <div className="d-flex flex-column align-items-center">
            {stepStatuses[1] === "Completed" &&
              stepStatuses[2] === "Pending" && (
                <button
                  className="file-upload-btn mt-2"
                  onClick={() => setShowFollowUpModal(true)}
                >
                  <i className="fas fa-comments"></i>
                </button>
              )}
            {isMessageSubmitted && (
              <button
                className="btn btn-success btn-sm mt-2"
                onClick={() => onStepComplete(index)}
              >
                Submit
              </button>
            )}
          </div>
        );
      }

      // Handle Customer Purchase Order step
      if (step.title === "Customer Purchase Order") {
        return (
          <>
            <input
              type="file"
              id={`file-step-${index}`}
              className="file-upload"
              onChange={handleFileUpload}
            />
            <div className="d-flex">
              <label
                htmlFor={`file-step-${index}`}
                className="file-upload-btn mx-2"
              >
                <i className="fas fa-upload"></i>
              </label>
            </div>
            {selectedFile && (
              <button
                className={`btn mt-2 btn-xs ${selectedFile ? "active" : ""}`}
                onClick={handleFileSubmit}
              >
                Submit
              </button>
            )}
          </>
        );
      }

      // For all other steps, return empty div to maintain spacing
      return <div className="file-upload-wrapper"></div>;
    };

    return (
      <div className="step-wrapper">
        <div className="step-content">
          <h6 className="step-title">{step.title}</h6>
          <div
            ref={circleRef}
            className={`step-circle ${getCircleClassName()}`}
            id={`step-circle-${index}`}
            onClick={() => handleCircleClick(index, step.title)}
          >
            <i className={`fas fa-${step.icon}`}></i>
            <div className="step-tooltip">{step.tooltip}</div>
          </div>

          {stepStatuses[index] === "Completed" && (
            <div className="completed-text">
              <h6 className="text-success">Completed</h6>
            </div>
          )}

          <div className="file-upload-wrapper">{renderStepContent()}</div>
        </div>
      </div>
    );
  };

  const FilePopup = ({ position, onClose }) => (
    <div
      className="action-popup show"
      style={{
        position: "absolute",
        top: position ? `${position.top + position.height - 40}px` : "0",
        left: position ? `${position.left + position.width / 2 - 290}px` : "0",
      }}
    >
      <button
        className="close-popup-btn"
        onClick={onClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          fontSize: "20px",
          cursor: "pointer",
        }}
      >
        &times;
      </button>
      <div className="fetched-files">
        {purchaseOrderFiles.length === 0 ? ( // Check if there are no files
          <div className="text-center text-muted">
            <p>No purchase order files found.</p>
          </div>
        ) : (
          purchaseOrderFiles.map((fileName, index) => (
            <div className="fetched-file" key={index}>
              <div className="file-info">
                <div className="file-name">
                  <i className="fas fa-file"></i>
                  <span>{fileName}</span>
                </div>
              </div>
              <div className="popup-actions">
                <Button
                  variant="success"
                  className="mx-2"
                  onClick={() =>
                    window.open(
                      `https://qabooks.zarp.software/uploads/purchaseOrderFiles/${fileName}`,
                      "_blank"
                    )
                  }
                >
                  <i className="fas fa-eye"></i> Preview
                </Button>
                <Button
                  variant="primary"
                  className="mx-2"
                  onClick={() =>
                    window.open(
                      `https://qabooks.zarp.software/uploads/purchaseOrderFiles/${fileName}`,
                      "_blank"
                    )
                  }
                >
                  <i className="fas fa-download"></i> Download
                </Button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {showTrackOrder && (
        <Container fluid className="py-4">
          <Row className="justify-content-center">
            <Col lg={12} md={12}>
              <div className="my-3">
                <div className="px-2 d-flex align-items-center">
                  <div className="back-button" onClick={handleCancleClick}>
                    <ArrowBackIosIcon size={20} className="arrow-child" />
                  </div>
                  <h5
                    className="mt-1 mx-2 text-primary"
                    style={{ letterSpacing: "1px" }}
                  >
                    Track Order
                  </h5>
                </div>
              </div>

              <Card className="card-style">
                {newValidationMessage && (
                  <Alert severity="error" className="mt-0">
                    {newValidationMessage.split("\n").map((message, index) => (
                      <div key={index}>{message}</div>
                    ))}
                  </Alert>
                )}{" "}
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>
                      <h6>
                        Enquiry ID:{" "}
                        <span className="mt-1 mx-2 text-primary font-20">
                          {batchNo}
                        </span>{" "}
                      </h6>

                      <h6>
                        Customer Name:{" "}
                        <span className="mt-1 mx-2 text-primary font-20">
                          {customerName}
                        </span>{" "}
                      </h6>
                    </div>
                    <div>
                      {/* <Button
                        variant="primary"
                        className="mx-2"
                        onClick={() => setShowMessagesModal(true)}
                      >
                        Follow-Up Messages
                      </Button> */}
                    </div>
                  </div>

                  <div className="text-center">
                    <div
                      className="main-content-label mb-4"
                      style={{ fontSize: "24px", fontWeight: "bold" }}
                    >
                      Add Enquiry Steps
                    </div>
                    <div className="progress-snake">
                      {steps.map((step, index) => (
                        <FileUploadStep
                          key={index}
                          step={step}
                          index={index}
                          activeStepIndex={activeStepIndex}
                          onStepComplete={handleStepCompletion}
                          completedSteps={completedSteps}
                        />
                      ))}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {activePopup !== null && (
            <FilePopup
              position={activePopup.position}
              onClose={() => setActivePopup(null)}
            />
          )}

          <Modal
            show={showFollowUpModal}
            onHide={() => setShowFollowUpModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Follow-up Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Message Title</Form.Label>
                  <Form.Control
                    type="text"
                    className="message-title"
                    placeholder="Enter Message Title"
                    value={messageTitle}
                    onChange={(e) => setMessageTitle(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3 w-100">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    className="form-control message-box"
                    name="current_address"
                    required
                    value={messageContent}
                    onChange={(e) => setMessageContent(e.target.value)}
                    placeholder="Enter Your Message"
                  />
                </Form.Group>
                <Form.Group className="mb-3 next-followup-date">
                  <Form.Label>Next Follow-up Date</Form.Label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    required
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date()}
                    placeholderText="Next Follow-Up Date"
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowFollowUpModal(false)}
              >
                Close
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  insertFollowupMessage();
                  setShowFollowUpModal(false);
                }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showMessagesModal}
            onHide={() => setShowMessagesModal(false)}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Follow-Up Messages</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {followUpMessages.length === 0 ? ( // Check if there are no messages
                <div className="text-center text-muted">
                  <p>No follow-up messages.</p>
                </div>
              ) : (
                <ul className="list-unstyled">
                  {followUpMessages.map((msg) => (
                    <li key={msg.id} className="mb-3 pb-3 border-bottom">
                      <h6>&bull;&nbsp;{msg.title}</h6>
                      <p>{msg.description}</p>
                      <small className="text-muted">
                        {new Date(msg.date).toLocaleString()}
                      </small>
                    </li>
                  ))}
                </ul>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowMessagesModal(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      )}

      {showSalesOrder && (
        <SalesOrderNew
          customerName={custName}
          customerId={custId}
          enqNo={enqId}
          handleCancel={handleCancleSalesOrder}
        />
      )}

      {showDeliveryNote && (
        <DeliveryNote
          customerName={custName}
          customerId={custId}
          enqNo={enqId}
          handleCancel={handleCancleDeliveryNote}
        />
      )}

      {showQuotation && (
        <QuotationNew
          customerName={customerName}
          customerId={customerId}
          batchNo={batchNo}
          handleCanel={handleCancelQuotation}
        />
      )}
    </>
  );
};

export default OrderTracking;
