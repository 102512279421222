import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Modal } from "react-bootstrap";
import "../../../asset/css/inbox.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import NoDataImage from "../../../asset/images/nodata.jpg";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import Alert from "@mui/material/Alert";
import EnquiryDetails from "./EnquiryDetails";
import QuotationNew from "../SalesNew/QuotationNew";
import OrderTracking from "../TrackReport/OrderTracking";

function Enquiry({ navigation }) {
  const [itemCards, setItemCard] = useState([]);
  const [newItem, setnewItem] = useState([]);
  const [itemId, setItemID] = useState("");
  const [loading, setLoading] = useState(true);
  const [showCard, setShowCard] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showQuoation, setShowQuoation] = useState(false);
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [showSalesModal, setShowSalesModal] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [inquiryId, setInquiryId] = useState("");

  const handleSalesClose = () => {
    setShowSalesModal(false);
    setValidationMessage("");
  };

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleItemsClick = () => {
    handleNavigation("EnquiryNew");
  };
  const handleEdit = (id) => {
    setItemID(id);
    setShowCard(false);
    setShowUpdate(true);
  };

  const handleGoToQuotation = (customerName, customerId, inquiryId) => {
    setCustomerName(customerName);
    setCustomerId(customerId);
    setInquiryId(inquiryId);
    setShowQuoation(true);
    setShowCard(false);
    setShowDetails(false);
    setShowUpdate(false);
  };
  const handleFollowUp = (customerName, customerId, inquiryId) => {
    setCustomerName(customerName);
    setCustomerId(customerId);
    setInquiryId(inquiryId);
    setShowQuoation(false);
    setShowCard(false);
    setShowDetails(false);
    setShowUpdate(false);
    setShowFollowUp(true);
  };

  const handleCancelFollow = () => {
    setShowQuoation(false);
    setShowCard(true);
    setShowDetails(false);
    fetchItemData();
    setShowUpdate(false);
    setShowFollowUp(false);
  };

  const [validationMessage, setValidationMessage] = useState("");

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this item!"
    );
    const jwtToken = localStorage.getItem("jwtToken");
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    if (userConfirmed) {
      // If the user clicks "OK", proceed with the deletion
      fetch(BASE_URL + `/deleteItem/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId,inquiryId:id }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setItemCard((prevItems) =>
              prevItems.filter((itemCards) => itemCards.id !== id)
            );

            swal({
              icon: "success",
              title: "Item Deleted Successfully",
            });
          } else if (
            data.responseMessage ===
            "Items which are a part of other transactions cannot be deleted. Instead, mark them as inactive."
          ) {
            window.confirm(
              "Items which are a part of other transactions cannot be deleted!"
            );
          } else {
            console.error("Failed to delete Item");
          }
        })
        .catch((error) => {
          console.error("Error deleting Item:", error);
        });
    } else {
      alert("Item deletion canceled!");
    }
  };

  const handleCancelUpdate = () => {
    fetchItemData();
    setShowUpdate(false);
    setShowCard(true);
  };

  const handleCancelDetails = () => {
    setShowDetails(false);
    setShowCard(true);
    fetchItemData();
    setShowUpdate(false);
    setShowQuoation(false);
  };

  const fetchItemData = () => {
    $("#DataTables_Table_0").DataTable();
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");
    const postApiUrl = BASE_URL + `/getInquiriesByUniqueId`;
    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setItemCard(data.data);
        } else {
          console.error("Failed to fetch invoices:", data.data);
        }
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  let i = 1;

  const handleItem = (name, id) => {
    setItemID(id);
    setnewItem(name);
    setShowDetails(true);
    setShowCard(false);
    setShowUpdate(false);
    setShowQuoation(false);
  };
  const handleCancelQuotes = () => {
    setShowCard(true);
    setShowQuoation(false);
    fetchItemData();
    setShowDetails(false);
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      {!showUpdate && !showDetails && !showQuoation && !showFollowUp && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Enquiry
            </div>
            <div className="button-container d-flex">
              <button
                className="btn btn-primary px-1 py-2 new-button"
                onClick={handleItemsClick}
              >
                + New Enquiry
              </button>
            </div>
          </div>
        </div>
      )}
      {showCard && (
        <div
          className="container-fluid"
          style={{ border: "none", borderRadius: "7px" }}
        >
          <div className="page-content ">
            <Container fluid={true} className="">
              <Row>
                <Col>
                  <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    {itemCards && itemCards.length > 0 ? (
                      <>
                        <div className="body">
                          <div className="table-responsive1">
                            <div
                              id="DataTables_Table_0_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                    id="DataTables_Table_0"
                                    role="grid"
                                    aria-describedby="DataTables_Table_0_info"
                                  >
                                    <thead className="heading-text">
                                      <tr role="row">
                                        <th
                                          className="sorting_asc"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Date: activate to sort column descending"
                                        >
                                          Serial no.
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                        >
                                          Customer Name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Amount BTC: activate to sort column ascending"
                                        >
                                          Enquiry Id
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="BTC Remaining: activate to sort column ascending"
                                        >
                                          Enquiry Date
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >Actions</th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >Action</th>
                                      </tr>
                                    </thead>
                                    <tbody className="text1">
                                      {itemCards.map((data) => (
                                        <tr
                                          role="row"
                                          className="odd"
                                          key={data.id}
                                        >
                                          <td>{i++}</td>
                                          <td className="sorting_1">
                                            {" "}
                                            <a
                                              href="#"
                                              onClick={() =>
                                                handleItem(
                                                  data.customerName,
                                                  data.inquiryId
                                                )
                                              }
                                            >
                                              {data.customerName}
                                            </a>
                                          </td>
                                          <td>{data.inquiryId}</td>
                                          <td>{data.createdDate}</td>

                                          <td>
                                            <button
                                              className="btn btn-primary btn-sm mr-2"
                                              onClick={() =>
                                                handleGoToQuotation(
                                                  data.customerName,
                                                  data.customerId,
                                                  data.inquiryId
                                                )
                                              }
                                            >
                                              Go To Quotation
                                            </button>
                                            <button
                                              className="btn btn-success btn-sm mr-2"
                                              onClick={() =>
                                                handleFollowUp(
                                                  data.customerName,
                                                  data.customerId,
                                                  data.inquiryId
                                                )
                                              }
                                            >
                                              Follow Up
                                            </button>
                                          </td>
                                          <td>
                                            {/* <button
                                              className="btn icon-border btn-sm mr-2"
                                              onClick={() =>
                                                handleEdit(data.inquiryId)
                                              }
                                            >
                                              <i className="fa fa-edit text1"></i>
                                            </button> */}
                                            <button
                                              className="btn icon-border btn-sm"
                                              onClick={() =>
                                                handleDelete(data.inquiryId)
                                              }
                                            >
                                              <i className="fa fa-trash text1"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-con
                                        trols="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Serial no.
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Customer name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Enquiry Id
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        Enquiry Date
                                      </th>

                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                                <h6 className="text-center">
                                  <img
                                    src={NoDataImage}
                                    style={{ width: "50%", height: "50%" }}
                                    alt="No Data Logo"
                                  />{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
      {showDetails && (
        <EnquiryDetails Itemid={itemId} handleCancle={handleCancelDetails} />
      )}

      {showQuoation && (
        <QuotationNew
          customerName={customerName}
          customerId={customerId}
          batchNo={inquiryId}
          handleCanel={handleCancelQuotes}
        />
      )}

      {showFollowUp && (
        <OrderTracking
          customerName={customerName}
          customerId={customerId}
          batchNo={inquiryId}
          handleCancle={handleCancelFollow}
        />
      )}
    </div>
  );
}

export default Enquiry;
