import React, { Component } from "react";
import JoditEditor from "jodit-react";

class TinyMceEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: this.props.initialContent || "", // Initialize with prop content
    };

    this.editor = null;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialContent !== prevProps.initialContent) {
      this.setState({ content: this.props.initialContent });
    }
  }

  handleChange(newContent) {
    this.setState({ content: newContent });
    if (this.props.onChange) {
      this.props.onChange(newContent);
    }
  }

  render() {
    const config = {
      toolbar: true,
      buttons: [
        "source", "|",
        "bold", "italic", "underline", "strikethrough", "|",
        "superscript", "subscript", "|",
        "ul", "ol", "outdent", "indent", "|",
        "align", "font", "fontsize", "brush", "paragraph", "|",
        "image", "video", "link", "|",
        "table", "hr", "eraser", "copyformat", "|",
        "fullsize", "print", "preview",
      ],
      height: 500,
    };

    return (
      <JoditEditor
        ref={(el) => (this.editor = el)}
        value={this.state.content}
        config={config}
        onChange={this.handleChange}
      />
    );
  }
}

export default TinyMceEditor;
