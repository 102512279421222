import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import "../../../asset/css/inbox.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import NoDataImage from "../../../asset/images/nodata.jpg";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import QuotesUpdate from "./QuotesUpdate";
import QuotesDetails from "./QuotesDetails";
import OrderTracking from "../TrackReport/OrderTracking";

function Quotes({ navigation }) {
  const [itemId, setItemID] = useState();
  const [loading, setLoading] = useState(true);
  const [showCard, setShowCard] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showFollowUp, setShowFollowUp] = useState(false);

  const [fetchedStatus, setFetchedStatus] = useState("");

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleInvoicesClick = () => {
    handleNavigation("quotesnew");
  };

  const handleEdit = (id, status) => {
    setFetchedStatus(status);
    setItemID(id);
    setShowCard(false);
    setShowUpdate(true);
  };

  const [invoices, setInvoices] = useState([]);
  const [newinvoiceNumber, setnewInvoice] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [inquiryId, setInquiryId] = useState("");
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const handleCancelUpdate = () => {
    fetchInvoices();
    setShowUpdate(false);
    setShowCard(true);
  };

  const handleCancelDetails = () => {
    setShowDetails(false);
    setShowCard(true);
    setShowUpdate(false);
    fetchInvoices();
  };

  const fetchInvoices = () => {
    $("#DataTables_Table_0").DataTable();
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/fetchQuote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setInvoices(data.responseMessage);
          setLoading(false);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
          setLoading(false);
        }
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      });
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure ? Once deleted, you will not be able to recover this invoice!"
    );

    if (userConfirmed) {
      // Send a DELETE request to your backend API
      fetch(BASE_URL + `/deleteByQuoteNumber/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      })
        .then((response) => {
          if (response.status === 200) {
            setInvoices((prevInvoices) =>
              prevInvoices.filter((invoice) => invoice.invoiceNumber !== id)
            );
            swal({
              icon: "success",
              title: "Quote Deleted Successfully",
            });
            fetchInvoices();
            setLoading(true);
          } else {
            console.error("Failed to delete invoice");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error deleting invoice:", error);
          setLoading(false);
        });
    } else {
      // If the user clicks "Cancel", show a cancellation message
      alert("Invoice deletion canceled!");
    }
  };

  const handleInvoice = (invoiceNumber, customerId) => {
    setnewInvoice(invoiceNumber);
    setCustomerId(customerId);
    setShowDetails(true);
    setShowCard(false);
    setShowUpdate(false);
  };

  const handleSentFollowUp = (customerName, customerId, inquiryId) => {
    setCustomerName(customerName);
    setCustomerId(customerId);
    setInquiryId(inquiryId);
    setShowFollowUp(true);
    setShowCard(false);
  };
  const handleCancelFollow = () => {
    setShowFollowUp(false);
    setShowCard(true);
    setShowDetails(false);
    setShowUpdate(false);
    fetchInvoices();
  };

  return (
    <div className="container-fluid">
      {!showUpdate && !showDetails && !showFollowUp && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Quotation
            </div>
            <div className="d-flex">
              <div className="">
                <button
                  className="btn btn-primary px-1 py-2 new-purchase"
                  onClick={handleInvoicesClick}
                >
                  + New Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCard && (
        <div className="container-fluid">
          <div className="page-content ">
            <Container fluid={true}>
              <Row>
                <Col>
                  <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    {invoices && invoices.length > 0 ? (
                      <>
                        <div className="body">
                          <div className="table-responsive1">
                            <div
                              id="DataTables_Table_0_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    className="table table-hover js-basic-example dataTable table-custom no-footer"
                                    id="DataTables_Table_0"
                                    role="grid"
                                    aria-describedby="DataTables_Table_0_info"
                                  >
                                    <thead className="heading-text">
                                      <tr role="row">
                                        <th
                                          className="sorting_asc"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Date: activate to sort column descending"
                                        >
                                          Date
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                        >
                                          Quotes#
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Amount BTC: activate to sort column ascending"
                                        >
                                          Reference
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="BTC Remaining: activate to sort column ascending"
                                        >
                                          Customer name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Status
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Total
                                        </th>

                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >Action</th>

                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody className="text1">
                                      {invoices.map((invoice) => (
                                        <tr
                                          role="row"
                                          className="odd"
                                          key={invoice.id}
                                        >
                                          <td>{invoice.quoteDate}</td>

                                          <td className="sorting_1">
                                            <a
                                              href="#"
                                              onClick={() =>
                                                handleInvoice(
                                                  invoice.quoteNumber,
                                                  invoice.customerId
                                                )
                                              }
                                            >
                                              {invoice.quoteNumber}
                                            </a>
                                          </td>
                                          <td>{invoice.orderNumber}</td>
                                          <td>{invoice.customerName}</td>
                                          <td>{invoice.status}</td>
                                          <td>₹{invoice.total}</td>
                                          <td>
                                            <button
                                              className="btn btn-success btn-sm mr-2"
                                              onClick={() =>
                                                handleSentFollowUp(
                                                  invoice.customerName,
                                                  invoice.customerId,
                                                  invoice.inquiryId
                                                )
                                              }
                                            >
                                              Follow Up
                                            </button>
                                          </td>
                                          <td>
                                            <button
                                              className="btn icon-border btn-sm mr-2"
                                              onClick={() =>
                                                handleEdit(
                                                  invoice.quoteNumber,
                                                  invoice.status
                                                )
                                              }
                                            >
                                              <i className="fa fa-edit text1"></i>
                                            </button>
                                            <button
                                              className="btn icon-border btn-sm"
                                              onClick={() =>
                                                handleDelete(
                                                  invoice.quoteNumber
                                                )
                                              }
                                            >
                                              <i className="fa fa-trash text1"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-con
                                        trols="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Quotes#
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Reference
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        Customer name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Due date
                                      </th>

                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Amount
                                      </th>

                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Balance due
                                      </th>

                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Status
                                      </th>

                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                                <h6 className="text-center">
                                  <img
                                    src={NoDataImage}
                                    style={{ width: "50%", height: "50%" }}
                                    alt="No Data Logo"
                                  />{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}

      {showUpdate && (
        <QuotesUpdate
          invoiceNumber1={itemId}
          handleCancel={handleCancelUpdate}
          fetchedStatus={fetchedStatus}
        />
      )}

      {showDetails && (
        <QuotesDetails
          newinvoiceNumber={newinvoiceNumber}
          custId={customerId}
          handleCancel={handleCancelDetails}
        />
      )}

      {showFollowUp && (
        <OrderTracking
          customerName={customerName}
          customerId={customerId}
          batchNo={inquiryId}
          handleCancle={handleCancelFollow}
        />
      )}
    </div>
  );
}
export default Quotes;
