import {
  faBriefcase,
  faBullhorn,
  faCalendarAlt,
  faCertificate,
  faCogs,
  faEnvelope,
  faFileUpload,
  faGenderless,
  faGraduationCap,
  faHeart,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import "./NewCampaign.css";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";

const HRSCandidateData = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newValidationMessage, setValidationMessage] = useState("");

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");

  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [alternateMobileNumber, setAlternateMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [highestEducationQualification, setHighestEducationQualification] =
    useState("");
  const [experience, setExperience] = useState("");
  const [topThreeSkills, setTopThreeSkills] = useState("");
  const [certificate, setCertificate] = useState("");
  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState("");
  const [resume, setResume] = useState(null);
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    setDateOfBirth(new Date());
  }, []);
  const handleDate = (date) => {
    setDateOfBirth(date);
  };

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleItemsClick = () => {
    handleNavigation("HRSCandidateCard");
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleItemsClick();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const handleFileChange = (file) => {
    if (!file) return;

    const fileType = file.type;
    const fileName = file.name;

    if (fileType === "application/pdf") {
      setResume(file);
    } else {
      alert("Please upload a PDF file.");
    }
  };

  const validateForm = () => {
    let newValidationMessage = "";

    if (!name) {
      newValidationMessage += "Please enter the candidate's name.\n";
    } else if (!position) {
      newValidationMessage += "Please enter the position.\n";
    } else if (!mobileNumber) {
      newValidationMessage += "Please enter the mobile number.\n";
    } else if (!/^\d{10}$/.test(mobileNumber)) {
      newValidationMessage += "Mobile number must be a 10-digit number.\n";
    } else if (!alternateMobileNumber) {
      newValidationMessage += "Please enter the alternate mobile number.\n";
    } else if (!/^\d{10}$/.test(alternateMobileNumber)) {
      newValidationMessage += "Alternate Mobile number must be a 10-digit number.\n";
    } else if (!email) {
      newValidationMessage += "Please enter the email address.\n";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newValidationMessage += "Please enter a valid email address.\n";
    } else if (!gender) {
      newValidationMessage += "Please select the gender.\n";
    } else if (!maritalStatus) {
      newValidationMessage += "Please select the marital status.\n";
    } else if (!highestEducationQualification) {
      newValidationMessage +=
        "Please enter the highest education qualification.\n";
    } else if (!experience) {
      newValidationMessage += "Please enter the years of experience.\n";
    } else if (!topThreeSkills) {
      newValidationMessage += "Please enter the top three skills.\n";
    } else if (!resume) {
      newValidationMessage += "Please upload a resume.\n";
    }

    setValidationMessage(newValidationMessage);
    return newValidationMessage === ""; // Return true if no validation messages
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      window.scrollTo(0, 0); // Scroll to top if validation fails
      return; // Stop submission if validation fails
    }
    try {
      const formData = new FormData();
      const candidateData = {
        uniqueId: decryptedUniqueId,
        name: name,
        position: position,
        mobileNumber: mobileNumber,
        alternateMobileNumber: alternateMobileNumber,
        email: email,
        dateOfBirth: dateOfBirth,
        gender: gender,
        maritalStatus: maritalStatus,
        highestEducationQualification: highestEducationQualification,
        experience: experience,
        topThreeSkills: topThreeSkills,
        certificate: certificate,
        howDidYouHearAboutUs: howDidYouHearAboutUs,
      };

      formData.append("candidateData", JSON.stringify(candidateData));

      // Append files
      if (resume) {
        formData.append("resume", resume); // Append the file
      }

      const response = await fetch(BASE_URL + "/createCandidate", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`, // JWT token
        },
        body: formData, // Attach FormData
      });

      const result = await response.json();

      if (response.ok) {
        swal({
          icon: "success",
          title: "Enquiry Inserted Successfully",
        });
        handleItemsClick();
      } else {
        swal({
          icon: "error",
          title: "Failed to insert item",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error Submitting Candidate Data: " + error.message);
    }
  };

  if (loading) {
    return (
      <div className="center-div">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="my-3">
            <div className="px-2 d-flex">
              <div className="cursor-pointer d-flex justify-content-center align-items-center back-button">
                <ArrowBackIosIcon
                  width={2}
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <h5
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                Candidate Application
              </h5>
            </div>
          </div>
          <Card className="card-style mt-3">
            {newValidationMessage && (
              <Alert severity="error" className="mt-0">
                {newValidationMessage.split("\n").map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </Alert>
            )}{" "}
            <Card.Body>
              <Row>
                <Col>
                  <h4 className="text-primary mb-4 mx-3">
                    Candidate Application Form
                  </h4>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Name of Candidate{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faUser} />
                          </span>
                          <Form.Control
                            type="text"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            placeholder="Enter Your Full Name"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Position <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faBriefcase} />
                          </span>
                          <Form.Control
                            type="text"
                            name="position"
                            value={position}
                            onChange={(e) => setPosition(e.target.value)}
                            required
                            placeholder="Position Applying For"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Mobile Number <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faPhone} />
                          </span>
                          <Form.Control
                            type="tel"
                            name="mobileNumber"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            required
                            placeholder="Enter Mobile Number"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Alternate Mobile Number
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faPhone} />
                          </span>
                          <Form.Control
                            type="tel"
                            name="alternateMobileNumber"
                            value={alternateMobileNumber}
                            onChange={(e) =>
                              setAlternateMobileNumber(e.target.value)
                            }
                            placeholder="Enter Alternate Mobile Number"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Email <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faEnvelope} />
                          </span>
                          <Form.Control
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Enter Email Address"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Date of Birth <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span
                            className="input-group-text"
                            style={{ width: "7%" }}
                          >
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </span>
                          <DatePicker
                            selected={dateOfBirth}
                            onChange={(date) => setDateOfBirth(date)}
                            style={{ width: "93%" }}
                            className="form-control"
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Select Date"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    {/* Gender field */}
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Gender <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faUser} />
                          </span>
                          <select
                            className="form-control form-select"
                            name="gender"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            required
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </Form.Group>
                    </Col>

                    {/* Marital Status field */}
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Marital Status <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faUser} />
                          </span>
                          <select
                            className="form-control form-select"
                            name="maritalStatus"
                            value={maritalStatus}
                            onChange={(e) => setMaritalStatus(e.target.value)}
                            required
                          >
                            <option value="">Select Marital Status</option>
                            <option value="Single">Single</option>
                            <option value="Married">Married</option>
                            <option value="Divorced">Divorced</option>
                          </select>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Highest Educational Qualification{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faGraduationCap} />
                          </span>
                          <Form.Control
                            type="text"
                            name="highestEducationQualification"
                            value={highestEducationQualification}
                            onChange={(e) =>
                              setHighestEducationQualification(e.target.value)
                            }
                            required
                            placeholder="Enter Your Qualification"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Experience (Years){" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faBriefcase} />
                          </span>
                          <Form.Control
                            type="number"
                            name="experience"
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                            required
                            placeholder="Years of Experience"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Top Three Skills{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faCogs} />
                          </span>
                          <Form.Control
                            type="text"
                            name="topThreeSkills"
                            value={topThreeSkills}
                            onChange={(e) => setTopThreeSkills(e.target.value)}
                            required
                            placeholder="Enter Your Top Skills"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">Certifications</label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faCertificate} />
                          </span>
                          <Form.Control
                            type="text"
                            name="certificate"
                            value={certificate}
                            onChange={(e) => setCertificate(e.target.value)}
                            placeholder="Enter Relevant Certifications"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          How did you hear about us?{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faBullhorn} />
                          </span>
                          <Form.Control
                            type="text"
                            name="howDidYouHearAboutUs"
                            value={howDidYouHearAboutUs}
                            onChange={(e) =>
                              setHowDidYouHearAboutUs(e.target.value)
                            }
                            required
                            placeholder="How did you hear about this opportunity?"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Upload Resume <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faFileUpload} />
                          </span>
                          <Form.Control
                            className="form-control file-input-container"
                            type="file"
                            name="resume"
                            onChange={(e) =>
                              handleFileChange(e.target.files[0])
                            }
                            required
                            accept=".pdf"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12" className="mb-3">
                      <Form.Group className="d-flex align-items-center">
                        <Form.Check
                          type="checkbox"
                          name="consent"
                          checked={consent}
                          onChange={(e) => setConsent(e.target.checked)}
                          required
                          id="consent-checkbox"
                        />
                        <label
                          className="ms-2 label-style"
                          htmlFor="consent-checkbox"
                        >
                          I consent to the processing of my personal data for
                          recruitment purposes
                        </label>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="text-right invoice-bottombtns">
                    <button
                      className="btn-1 btn mt-0"
                      disabled={loading}
                      onClick={handleCancleClick}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn-3 btn btn-primary"
                      onClick={handleSubmit}
                    >
                      {isLoading ? "Saving..." : "Create Candidate"}
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HRSCandidateData;
