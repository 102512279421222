import {
  faBriefcase,
  faBullhorn,
  faCalendarAlt,
  faCertificate,
  faCogs,
  faEnvelope,
  faFileUpload,
  faGenderless,
  faGraduationCap,
  faHeart,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import Alert from "@mui/material/Alert";
import "./NewCampaign.css";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";

const HRSCandidateUpdate = (props) => {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newValidationMessage, setValidationMessage] = useState("");
  const { id } = props;
  const [candidateData, setCandidateData] = useState({
    id: "",
    name: "",
    position: "",
    mobileNumber: "",
    alternateMobileNumber: "",
    email: "",
    dateOfBirth: new Date(),
    gender: "",
    maritalStatus: "",
    highestEducationQualification: "",
    experience: "",
    topThreeSkills: "",
    certificate: "",
    howDidYouHearAboutUs: "",
    resume: null,
    resumeFileName: "",
  });

  const [consent, setConsent] = useState(false);

  useEffect(() => {
    fetch(BASE_URL + `/getCandidateById`, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Candidate data : ", data);
        if (data.data) {
          // Ensure dateOfBirth is a valid date
          const fetchedData = {
            ...data.data,
            dateOfBirth: new Date(data.data.dateOfBirth) || new Date(), // Convert to Date object
            resumeFileName: data.data.resume.fileName,
          };
          setCandidateData(fetchedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      props.handleCancle();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const validateForm = () => {
    let newValidationMessage = "";

    if (!candidateData.name) {
      newValidationMessage += "Please enter the candidate's name.\n";
    } else if (!candidateData.position) {
      newValidationMessage += "Please enter the position.\n";
    } else if (!candidateData.mobileNumber) {
      newValidationMessage += "Please enter the mobile number.\n";
    } else if (!/^\d{10}$/.test(candidateData.mobileNumber)) {
      newValidationMessage += "Mobile number must be a 10-digit number.\n";
    } else if (!candidateData.alternateMobileNumber) {
      newValidationMessage += "Please enter the alternate mobile number.\n";
    } else if (!/^\d{10}$/.test(candidateData.alternateMobileNumber)) {
      newValidationMessage +=
        "Alternate Mobile number must be a 10-digit number.\n";
    } else if (!candidateData.email) {
      newValidationMessage += "Please enter the email address.\n";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(candidateData.email)) {
      newValidationMessage += "Please enter a valid email address.\n";
    } else if (!candidateData.gender) {
      newValidationMessage += "Please select the gender.\n";
    } else if (!candidateData.maritalStatus) {
      newValidationMessage += "Please select the marital status.\n";
    } else if (!candidateData.highestEducationQualification) {
      newValidationMessage +=
        "Please enter the highest education qualification.\n";
    } else if (!candidateData.experience) {
      newValidationMessage += "Please enter the years of experience.\n";
    } else if (!candidateData.topThreeSkills) {
      newValidationMessage += "Please enter the top three skills.\n";
    }

    setValidationMessage(newValidationMessage);
    return newValidationMessage === ""; // Return true if no validation messages
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      window.scrollTo(0, 0); // Scroll to top if validation fails
      return; // Stop submission if validation fails
    }
    const formData = new FormData();

    const candidateData1 = {
      id: candidateData.id,
      uniqueId: decryptedUniqueId,
      name: candidateData.name,
      position: candidateData.position,
      mobileNumber: candidateData.mobileNumber,
      alternateMobileNumber: candidateData.alternateMobileNumber,
      email: candidateData.email,
      dateOfBirth: candidateData.dateOfBirth,
      gender: candidateData.gender,
      maritalStatus: candidateData.maritalStatus,
      highestEducationQualification:
        candidateData.highestEducationQualification,
      experience: candidateData.experience,
      topThreeSkills: candidateData.topThreeSkills,
      certificate: candidateData.certificate,
      howDidYouHearAboutUs: candidateData.howDidYouHearAboutUs,
    };

    formData.append("candidateData", JSON.stringify(candidateData1));

    // Check if a new resume file is selected
    if (candidateData.resume) {
      formData.append("resume", candidateData.resume); // Append the new file
    } else {
      // If no new file is selected, append the existing file name
      formData.append("resumeFileName", candidateData.resumeFileName);
    }

    // Submit the form data
    try {
      const response = await fetch(BASE_URL + `/updateCandidate`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      });

      if (response.ok) {
        swal("Success", "Candidate updated successfully!", "success");
        props.handleCancle();
        setLoading(false);
        // Optionally, redirect or update the UI
      } else {
        swal("Error", "Failed to update candidate.", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      swal("Error", "An error occurred while updating the candidate.", "error");
    }
  };

  const handleFileChange = (file) => {
    setCandidateData((prevData) => ({
      ...prevData,
      resume: file, // Set the selected file
    }));
  };

  if (loading) {
    return (
      <div className="center-div">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="my-3">
            <div className="px-2 d-flex">
              <div className="cursor-pointer d-flex justify-content-center align-items-center back-button">
                <ArrowBackIosIcon
                  width={2}
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <h5
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                Candidate Application
              </h5>
            </div>
          </div>
          <Card className="card-style mt-3">
            {newValidationMessage && (
              <Alert severity="error" className="mt-0">
                {newValidationMessage.split("\n").map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </Alert>
            )}{" "}
            <Card.Body>
              <Row>
                <Col>
                  <h4 className="text-primary mb-4 mx-3">
                    Update Candidate Form
                  </h4>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Name of Candidate{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faUser} />
                          </span>
                          <Form.Control
                            type="text"
                            name="name"
                            value={candidateData.name}
                            onChange={(e) =>
                              setCandidateData({
                                ...candidateData,
                                name: e.target.value,
                              })
                            }
                            required
                            placeholder="Enter Your Full Name"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Position <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faBriefcase} />
                          </span>
                          <Form.Control
                            type="text"
                            name="position"
                            value={candidateData.position}
                            onChange={(e) =>
                              setCandidateData({
                                ...candidateData,
                                position: e.target.value,
                              })
                            }
                            required
                            placeholder="Position Applying For"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Mobile Number <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faPhone} />
                          </span>
                          <Form.Control
                            type="tel"
                            name="mobileNumber"
                            value={candidateData.mobileNumber}
                            onChange={(e) =>
                              setCandidateData({
                                ...candidateData,
                                mobileNumber: e.target.value,
                              })
                            }
                            required
                            placeholder="Enter Mobile Number"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Alternate Mobile Number
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faPhone} />
                          </span>
                          <Form.Control
                            type="tel"
                            name="alternateMobileNumber"
                            value={candidateData.alternateMobileNumber}
                            onChange={(e) =>
                              setCandidateData({
                                ...candidateData,
                                alternateMobileNumber: e.target.value,
                              })
                            }
                            placeholder="Enter Alternate Mobile Number"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Email <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faEnvelope} />
                          </span>
                          <Form.Control
                            type="email"
                            name="email"
                            value={candidateData.email}
                            onChange={(e) =>
                              setCandidateData({
                                ...candidateData,
                                email: e.target.value,
                              })
                            }
                            required
                            placeholder="Enter Email Address"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Date of Birth <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span
                            className="input-group-text"
                            style={{ width: "7%" }}
                          >
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </span>
                          <DatePicker
                            selected={candidateData.dateOfBirth}
                            onChange={(date) =>
                              setCandidateData({
                                ...candidateData,
                                dateOfBirth: date,
                              })
                            }
                            style={{ width: "93%" }}
                            className="form-control"
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Select Date"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    {/* Gender field */}
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Gender <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faUser} />
                          </span>
                          <select
                            className="form-control form-select"
                            name="gender"
                            value={candidateData.gender}
                            onChange={(e) =>
                              setCandidateData({
                                ...candidateData,
                                gender: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </Form.Group>
                    </Col>

                    {/* Marital Status field */}
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Marital Status <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faUser} />
                          </span>
                          <select
                            className="form-control form-select"
                            name="maritalStatus"
                            value={candidateData.maritalStatus}
                            onChange={(e) =>
                              setCandidateData({
                                ...candidateData,
                                maritalStatus: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="">Select Marital Status</option>
                            <option value="Single">Single</option>
                            <option value="Married">Married</option>
                            <option value="Divorced">Divorced</option>
                          </select>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Highest Educational Qualification{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faGraduationCap} />
                          </span>
                          <Form.Control
                            type="text"
                            name="highestEducationQualification"
                            value={candidateData.highestEducationQualification}
                            onChange={(e) =>
                              setCandidateData({
                                ...candidateData,
                                highestEducationQualification: e.target.value,
                              })
                            }
                            required
                            placeholder="Enter Your Qualification"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Experience (Years){" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faBriefcase} />
                          </span>
                          <Form.Control
                            type="number"
                            name="experience"
                            value={candidateData.experience}
                            onChange={(e) =>
                              setCandidateData({
                                ...candidateData,
                                experience: e.target.value,
                              })
                            }
                            required
                            placeholder="Years of Experience"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Top Three Skills{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faCogs} />
                          </span>
                          <Form.Control
                            type="text"
                            name="topThreeSkills"
                            value={candidateData.topThreeSkills}
                            onChange={(e) =>
                              setCandidateData({
                                ...candidateData,
                                topThreeSkills: e.target.value,
                              })
                            }
                            required
                            placeholder="Enter Your Top Skills"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">Certifications</label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faCertificate} />
                          </span>
                          <Form.Control
                            type="text"
                            name="certificate"
                            value={candidateData.certificate}
                            onChange={(e) =>
                              setCandidateData({
                                ...candidateData,
                                certificate: e.target.value,
                              })
                            }
                            placeholder="Enter Relevant Certifications"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          How did you hear about us?{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faBullhorn} />
                          </span>
                          <Form.Control
                            type="text"
                            name="howDidYouHearAboutUs"
                            value={candidateData.howDidYouHearAboutUs}
                            onChange={(e) =>
                              setCandidateData({
                                ...candidateData,
                                howDidYouHearAboutUs: e.target.value,
                              })
                            }
                            required
                            placeholder="How did you hear about this opportunity?"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Upload Resume <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faFileUpload} />
                          </span>
                          <Form.Control
                            className="form-control file-input-container"
                            type="file"
                            name="resume"
                            onChange={(e) =>
                              handleFileChange(e.target.files[0])
                            }
                            required
                            accept=".pdf"
                          />
                        </div>
                        {candidateData.resumeFileName && (
                          <div className="mt-2">
                            <h6>
                              Current Resume: {candidateData.resumeFileName}
                            </h6>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12" className="mb-3">
                      <Form.Group className="d-flex align-items-center">
                        <Form.Check
                          type="checkbox"
                          name="consent"
                          checked={consent}
                          onChange={(e) => setConsent(e.target.checked)}
                          required
                          id="consent-checkbox"
                        />
                        <label
                          className="ms-2 label-style"
                          htmlFor="consent-checkbox"
                        >
                          I consent to the processing of my personal data for
                          recruitment purposes
                        </label>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="text-right invoice-bottombtns">
                    <button
                      className="btn-1 btn mt-0"
                      disabled={loading}
                      onClick={handleCancleClick}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn-3 btn btn-primary"
                      onClick={handleSubmit}
                    >
                      {isLoading ? "Saving..." : "Update Candidate"}
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HRSCandidateUpdate;
