import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Modal } from "react-bootstrap";
import "../../../asset/css/inbox.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import NoDataImage from "../../../asset/images/nodata.jpg";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import Alert from "@mui/material/Alert";

function AppointmentCard({ navigation }) {
    const [itemCards, setItemCard] = useState([]);
    const [newItem, setnewItem] = useState([]);
    const [itemId, setItemID] = useState("");
    const [loading, setLoading] = useState(true);
    const [showCard, setShowCard] = useState(true);
    const [showUpdate, setShowUpdate] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [showSalesModal, setShowSalesModal] = useState(false);
    const [appointmentData,setAppointmentData] = useState([]);
  
    const handleNavigation = (key) => {
      if (navigation.state.routes[navigation.state.index].key !== key) {
        navigation.navigate(key);
      }
    };
    const handleItemsClick = () => {
      handleNavigation("NewAppointment");
    };
    const handleEdit = (id) => {
      setItemID(id);
      setShowCard(false);
      setShowUpdate(true);
    };
  
    const handleDelete = (id) => {
      
    };
  
    const handleCancelUpdate = () => {
      fetchItemData();
      setShowUpdate(false);
      setShowCard(true);
    };
  
    const handleCancelDetails = () => {
      setShowDetails(false);
      setShowCard(true);
      fetchItemData();
      setShowUpdate(false);
    };
  
    const fetchItemData = () => {
      $("#DataTables_Table_0").DataTable();
  
      const encryptedUniqueId = localStorage.getItem("uniqueId1");
      const decryptedUniqueId = AES.decrypt(
        encryptedUniqueId,
        "1234567812345678"
      ).toString(enc.Utf8);
      const jwtToken = localStorage.getItem("jwtToken");
  
      const postApiUrl = BASE_URL + `/getAppointmentsByUniqueId`;
  
      fetch(postApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId}),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setAppointmentData(data.data);
            console.log(data);
          } else {
            console.error("Failed to fetch invoices:", data.responseMessage);
          }
          setLoading(false);
          $("#DataTables_Table_0").DataTable();
        })
        .catch((error) => {
          console.error("Error making POST request:", error);
          setLoading(false);
          $("#DataTables_Table_0").DataTable();
        });
    };
  
    useEffect(() => {
      fetchItemData();
    }, []);
  
    let i = 1;
  
    const handleItem = (name, id) => {
      setItemID(id);
      setnewItem(name);
      setShowDetails(true);
      setShowCard(false);
      setShowUpdate(false);
    };
  
    if (loading) {
      return (
        <div className="container-fluid ">
          <div className="page-content">
            <div className="center-div">
              <div className="spinner"></div>
            </div>
          </div>
        </div>
      );
    }
  
    return (
      <div className="container-fluid">
        {!showUpdate && !showDetails && (
          <div className="item-page">
            <div className="d-flex justify-content-between">
              <div
                className="mt-2"
                style={{
                  color: "#3C7BD4",
                  fontSize: 20,
                  fontWeight: "600",
                  wordWrap: "break-word",
                  textAlign: "left",
                }}
              >
                Appointments
              </div>
              <div className="button-container d-flex">
                <button
                  className="btn btn-primary px-1 py-2 new-button"
                  onClick={handleItemsClick}
                >
                  + Appointment
                </button>
              </div>
            </div>
          </div>
        )}
        {showCard && (
          <div
            className="container-fluid"
            style={{ border: "none", borderRadius: "7px" }}
          >
            <div className="page-content ">
              <Container fluid={true} className="">
                <Row>
                  <Col>
                    <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                      {appointmentData && appointmentData.length > 0 ? (
                        <>
                          <div className="body">
                            <div className="table-responsive1">
                              <div
                                id="DataTables_Table_0_wrapper"
                                className="dataTables_wrapper dt-bootstrap4 no-footer"
                              >
                                <div className="row">
                                  <div className="col-sm-12">
                                    <table
                                      className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                      id="DataTables_Table_0"
                                      role="grid"
                                      aria-describedby="DataTables_Table_0_info"
                                    >
                                      <thead className="heading-text">
                                        <tr role="row">
                                          <th
                                            className="sorting_asc"
                                            tabIndex="0"
                                            aria-controls="DataTables_Table_0"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-sort="ascending"
                                            aria-label="Date: activate to sort column descending"
                                          >
                                            Serial no.
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex="0"
                                            aria-controls="DataTables_Table_0"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Type: activate to sort column ascending"
                                          >
                                            Customer Name
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex="0"
                                            aria-controls="DataTables_Table_0"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Amount BTC: activate to sort column ascending"
                                          >
                                            Staff Name
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex="0"
                                            aria-controls="DataTables_Table_0"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="BTC Remaining: activate to sort column ascending"
                                          >
                                            Date
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex="0"
                                            aria-controls="DataTables_Table_0"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="BTC Remaining: activate to sort column ascending"
                                          >
                                            Time
                                          </th>
                                          <th
                                            className="sorting"
                                            tabIndex="0"
                                            aria-controls="DataTables_Table_0"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Price: activate to sort column ascending"
                                          ></th>
                                        </tr>
                                      </thead>
                                      <tbody className="text1">
                                        {appointmentData.map((item) => (
                                          <tr
                                            role="row"
                                            className="odd"
                                            key={item.id}
                                          >
                                            <td>{i++}</td>
                                            <td className="sorting_1">
                                              {" "}
                                              <a
                                                href="#"
                                                onClick={() =>
                                                  handleItem(item.name, item.id)
                                                }
                                              >
                                                {item.customerName}
                                              </a>
                                            </td>
                                            <td>{item.staffName}</td>
                                            <td>{item.appointmentDate}</td>
                                            <td>{item.timeAuthentication}</td>
  
                                            <td>
                                              <button
                                                className="btn icon-border btn-sm mr-2"
                                                onClick={() =>
                                                  handleEdit(item.id)
                                                }
                                              >
                                                <i className="fa fa-edit text1"></i>
                                              </button>
                                              <button
                                                className="btn icon-border btn-sm"
                                                onClick={() =>
                                                  handleDelete(item.id)
                                                }
                                              >
                                                <i className="fa fa-trash text1"></i>
                                              </button>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="body">
                          <div className="table-responsive1">
                            <div
                              id="DataTables_Table_0_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                    id="DataTables_Table_0"
                                    role="grid"
                                    aria-describedby="DataTables_Table_0_info"
                                  >
                                    <thead className="heading-text">
                                      <tr role="row">
                                        <th
                                          className="sorting_asc"
                                          tabIndex="0"
                                          aria-con
                                          trols="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Date: activate to sort column descending"
                                        >
                                          Serial no.
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                        >
                                          Item name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Amount BTC: activate to sort column ascending"
                                        >
                                          Sales price
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="BTC Remaining: activate to sort column ascending"
                                        >
                                          Purchase price
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Usage unit
                                        </th>
  
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Type
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody></tbody>
                                  </table>
                                  <h6 className="text-center">
                                    <img
                                      src={NoDataImage}
                                      style={{ width: "50%", height: "50%" }}
                                      alt="No Data Logo"
                                    />{" "}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        )}
      </div>
    );
  }

  export default AppointmentCard;