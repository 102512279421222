import React, { useState, useEffect } from "react";
import { Row, Col, Form, Card, Button, Container } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../asset/vendor/font-awesome/css/font-awesome.min.css";
import "./NewCampaign.css";
import {
  faUser,
  faBuilding,
  faBriefcase,
  faCalendarAlt,
  faPhone,
  faEnvelope,
  faTint,
  faIdCard,
  faCreditCard,
  faHeart,
  faUsers,
  faFile,
  faHome,
  faFileAlt,
  faGraduationCap,
  faIndianRupeeSign,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { data } from "jquery";

const HRSEmployeeUpdate = (props) => {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");
  const { id } = props;

  const [selectedDate, setSelectedDate] = useState(null); // Add state for DatePicker
  const [empName, setEmpName] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [alternateContact, setAlternateContact] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [salary, setSalary] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const [parentName, setParentName] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [permanentAddresss, setPermanentAddress] = useState("");
  const [highestQualification, setHightestQualification] = useState("");
  const [previousCompany, setPreviousCompany] = useState("");
  const [previousDesignation, setPreviousDesignation] = useState("");
  const [aadharFile, setAadharFile] = useState(null);
  const [panCardFile, setPanCardFile] = useState(null);
  const [bankPassbook, setBankPassbook] = useState(null);
  const [twelthOrDiplomaMarks, setTwelthOrDiplomaMarks] = useState(null);
  const [addressProof, setAddressProof] = useState(null);
  const [experienceLetter, setExperienceLetter] = useState(null);
  const [postGradMarks, setPostGradMarks] = useState(null);
  const [gradOrDiplomaMarks, setGradOrDiplomaMarks] = useState(null);
  const [certificates, setCertificates] = useState(null);

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]); // Save the selected file
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      //   handleItemsClick();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/getHrsDataById`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ id: String(id) }),
        });
        const data = await response.json();
        if (response.ok) {
          const Data = data.data;
          setEmpName(Data.name);
          setDepartment(Data.department);
          setDesignation(Data.designation);
          setDateOfJoining(Data.dateOfJoining);
          setContactNumber(Data.contactNumber);
          setAlternateContact(Data.alternateNumber);
          setEmail(Data.personalEmail);
          setDob(Data.dateOfBirth);
          setBloodGroup(Data.bloodGroup);
          setSalary(Data.salary);
          setSpouseName(Data.spouseName);
          setParentName(Data.parentName);
          setCurrentAddress(Data.currentAddress);
          setPermanentAddress(Data.permanentAddress);
          setPreviousCompany(Data.previousCompanyName);
          setPreviousDesignation(Data.previousCompanyDesignation);
          setHightestQualification(Data.highestQualification);
        } else {
          console.error("Failed to fetch employee data");
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchEmployeeData();
  }, [id]);

  const handleUpdate = async () => {
    try {
      // Prepare FormData
      const formData = new FormData();
      const employeeData = {
        id: id,
        uniqueId: decryptedUniqueId,
        name: empName,
        department: department,
        designation: designation,
        dateOfJoining: dateOfJoining,
        contactNumber: contactNumber,
        alternateNumber: alternateContact,
        personalEmail: email,
        dateOfBirth: dob,
        bloodGroup: bloodGroup,
        salary: salary,
        spouseName: spouseName,
        parentName: parentName,
        currentAddress: currentAddress,
        permanentAddress: permanentAddresss,
        previousCompanyName: previousCompany,
        previousCompanyDesignation: previousDesignation,
        highestQualification: highestQualification,
      };

      // Append JSON as a string
      formData.append("employeeData", JSON.stringify(employeeData));
      if (aadharFile) formData.append("aadharCard", aadharFile);
      if (panCardFile) formData.append("panCard", panCardFile);
      if (twelthOrDiplomaMarks)
        formData.append("twelthOrDiplomaMarks", twelthOrDiplomaMarks);

      if (experienceLetter)
        formData.append("experienceLetter", experienceLetter);
      if (addressProof) formData.append("addressProof", addressProof);

      if (postGradMarks) formData.append("postGradMarks", postGradMarks);
      if (gradOrDiplomaMarks)
        formData.append("gradOrDiplomaMarks", gradOrDiplomaMarks);
      if (certificates) formData.append("certificates", certificates);

      if (bankPassbook) formData.append("bankPassbook", bankPassbook);

      const response = await fetch(`${BASE_URL}/updateHrsEmpData`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`, // JWT token
        },
        body: formData, // Attach FormData
      });
      const result = await response.json();
      if (response.ok) {
        swal({
          icon: "success",
          title: "Employee Updated Successfully",
        });
        // handleItemsClick();
      } else {
        swal({
          icon: "error",
          title: "Failed to update employee",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error updating employee: " + error.message);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="my-3">
            <div className="px-2 d-flex">
              <div className="cursor-pointer d-flex justify-content-center align-items-center back-button">
                <ArrowBackIosIcon
                  width={2}
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <h5
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                Employee Application
              </h5>
            </div>
          </div>
          <Card className="card-style">
            <Card.Body>
              <h4 className="text-primary mb-4">Employee Data Form</h4>

              <h5 className="text-secondary mb-3">Personal Information</h5>
              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Employee Name <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      <Form.Control
                        type="text"
                        name="empName"
                        value={empName}
                        onChange={(e) => setEmpName(e.target.value)}
                        required
                        placeholder="Enter Employee name"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Department <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faBuilding} />
                      </span>
                      <Form.Control
                        type="text"
                        name="department"
                        value={department}
                        onChange={(e) => setDepartment(e.target.value)}
                        required
                        placeholder="Enter Department"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Designation <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faBriefcase} />
                      </span>
                      <Form.Control
                        type="text"
                        name="designation"
                        value={designation}
                        onChange={(e) => setDesignation(e.target.value)}
                        required
                        placeholder="Enter Designation"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Date of Joining <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span
                        className="input-group-text"
                        style={{ width: "7%" }}
                      >
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                      <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        style={{ width: "93%" }}
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Select Date"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Contact Information */}
              <h5 className="text-secondary mb-3 mt-4">Contact Information</h5>
              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Contact Number <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faPhone} />
                      </span>
                      <Form.Control
                        type="tel"
                        name="contactNumber"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        required
                        placeholder="Enter Contact Number"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Alternate Contact <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faPhone} />
                      </span>
                      <Form.Control
                        type="tel"
                        name="alternateContact"
                        value={alternateContact}
                        onChange={(e) => setAlternateContact(e.target.value)}
                        required
                        placeholder="Enter Alternate Contact"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Personal Details */}
              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Personal Email <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </span>
                      <Form.Control
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="Enter Personal Email"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Date of Birth <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span
                        className="input-group-text"
                        style={{ width: "7%" }}
                      >
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                      <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        style={{ width: "93%" }}
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Select Date"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Blood Group <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faTint} />
                      </span>
                      <Form.Control
                        type="text"
                        name="bloodGroup"
                        value={bloodGroup}
                        onChange={(e) => setBloodGroup(e.target.value)}
                        required
                        placeholder="Enter Blood Group"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Salary <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faIndianRupeeSign} />
                      </span>
                      <Form.Control
                        type="number"
                        name="salary"
                        value={salary}
                        onChange={(e) => setSalary(e.target.value)}
                        required
                        placeholder="Enter Salary"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Document Uploads Section */}
              <h5 className="text-secondary mb-3 mt-4">Document Uploads</h5>
              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Aadhar Card <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faIdCard} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="aadharCard"
                        onChange={(e) => handleFileChange(e, setAadharFile)}
                        required
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      PAN Card <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faCreditCard} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="panCardFile"
                        onChange={(e) => handleFileChange(e, setPanCardFile)}
                        required
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Family Information */}
              <h5 className="text-secondary mb-3 mt-4">Family Information</h5>
              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">Spouse Name</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faHeart} />
                      </span>
                      <Form.Control
                        type="text"
                        name="spouseName"
                        value={spouseName}
                        onChange={(e) => setSpouseName(e.target.value)}
                        placeholder="Enter Spouse Name"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Parent Name <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faUsers} />
                      </span>
                      <Form.Control
                        type="text"
                        name="parentName"
                        value={parentName}
                        onChange={(e) => setParentName(e.target.value)}
                        required
                        placeholder="Enter Parent Name"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Bank Details */}
              <h5 className="text-secondary mb-3 mt-4">Bank Details</h5>
              <Row>
                <Col md="12" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Bank Passbook/Cheque{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faFile} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="bankPassbook"
                        onChange={(e) => handleFileChange(e, setBankPassbook)}
                        required
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Address Information */}
              <h5 className="text-secondary mb-3 mt-4">Address Information</h5>
              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Current Address <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faHome} />
                      </span>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        className="form-control"
                        name="currentAddress"
                        value={currentAddress}
                        onChange={(e) => setCurrentAddress(e.target.value)}
                        required
                        placeholder="Enter Current Address"
                        style={{
                          minHeight: "100px",
                          resize: "vertical",
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Permanent Address <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faHome} />
                      </span>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="permanentAddress"
                        className="form-control"
                        value={permanentAddresss}
                        onChange={(e) => setPermanentAddress(e.target.value)}
                        required
                        placeholder="Enter Permanent Address"
                        style={{
                          minHeight: "100px",
                          resize: "vertical",
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="12" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Address Proof <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faFileAlt} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="addressProof"
                        onChange={(e) => handleFileChange(e, setAddressProof)}
                        required
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              {/* Educational Information */}
              <h5 className="text-secondary mb-3 mt-4">
                Educational Information
              </h5>
              <Row>
                <Col md="12" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Highest Qualification{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faGraduationCap} />
                      </span>
                      <Form.Control
                        type="text"
                        name="highestQualification"
                        value={highestQualification}
                        onChange={(e) =>
                          setHightestQualification(e.target.value)
                        }
                        required
                        placeholder="Enter Highest Qualification"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Post Graduation Marksheets
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faFilePdf} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="postGradMarks"
                        onChange={(e) => handleFileChange(e, setPostGradMarks)}
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Graduation/Diploma Marksheets
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faFilePdf} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="gradOrDiplomaMarks"
                        onChange={(e) =>
                          handleFileChange(e, setGradOrDiplomaMarks)
                        }
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      12th/Diploma Marksheets
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faFilePdf} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="twelthOrDiplomaMarks"
                        onChange={(e) =>
                          handleFileChange(e, setTwelthOrDiplomaMarks)
                        }
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">
                      Additional Certificates
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faFilePdf} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="certificates"
                        onChange={(e) => handleFileChange(e, setCertificates)}
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <h5 className="text-secondary mb-3 mt-4">Previous Employment</h5>
              <Row>
                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">Previous Company Name</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faBuilding} />
                      </span>
                      <Form.Control
                        type="text"
                        name="previousCompany"
                        value={previousCompany}
                        onChange={(e) => setPreviousCompany(e.target.value)}
                        placeholder="Enter Previous Company Name"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" className="mb-3">
                  <Form.Group>
                    <label className="label-style">Previous Designation</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faBriefcase} />
                      </span>
                      <Form.Control
                        type="text"
                        name="previousDesignation"
                        value={previousDesignation}
                        onChange={(e) => setPreviousDesignation(e.target.value)}
                        placeholder="Enter Previous Designation"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="12" className="mb-3">
                  <Form.Group>
                    <label className="label-style">Experience Letter</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faFile} />
                      </span>
                      <Form.Control
                        type="file"
                        className="form-control file-input-container"
                        name="experienceLetter"
                        onChange={(e) =>
                          handleFileChange(e, setExperienceLetter)
                        }
                        accept=".pdf,.jpg,.jpeg,.png"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Confirmation */}
              <Row>
                <Col md="12" className="mb-3">
                  <Form.Group>
                    <div className="form-check">
                      <Form.Check
                        type="checkbox"
                        name="confirmation"
                        id="confirmation"
                        checked={""}
                        onChange={""}
                        label="I confirm that the information provided is accurate and up-to-date."
                        required
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* Submit Button */}
              <Row>
                <Col md="12">
                  <div className="d-flex justify-content-end mt-4">
                    <Button
                      variant="outline-secondary"
                      className="mx-2"
                      onClick={handleCancleClick}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      className="mx-2"
                      onClick={handleUpdate}
                    >
                      Submit Application
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HRSEmployeeUpdate;
