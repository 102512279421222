import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Modal } from "react-bootstrap";
import "../../../asset/css/inbox.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import "./ItemDetails.css";
import NoDataImage from "../../../asset/images/nodata.jpg";
import ItemDetails from "./ItemDetails";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import ItemUpdate from "./ItemUpdate";
import Alert from "@mui/material/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FlashSlash } from "iconic-react";

function Items({ navigation }) {
  const [itemCards, setItemCard] = useState([]);
  const [newItem, setnewItem] = useState([]);
  const [itemId, setItemID] = useState("");
  const [loading, setLoading] = useState(true);
  const [showCard, setShowCard] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleItemsClick = () => {
    handleNavigation("ItemsNew");
  };
  const handleEdit = (id) => {
    setItemID(id);
    setShowCard(false);
    setShowUpdate(true);
  };

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this item!"
    );

    if (userConfirmed) {
      // If the user clicks "OK", proceed with the deletion
      fetch(BASE_URL + `/deleteItem/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            // Remove the deleted item from the local state
            setItemCard((prevItems) =>
              prevItems.filter((itemCards) => itemCards.id !== id)
            );

            swal({
              icon: "success",
              title: "Item Deleted Successfully",
            });
          } else if (
            data.responseMessage ===
            "Items which are a part of other transactions cannot be deleted. Instead, mark them as inactive."
          ) {
            window.confirm(
              "Items which are a part of other transactions cannot be deleted!"
            );
          } else {
            console.error("Failed to delete Item");
            // fetchItemData();
            // setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error deleting Item:", error);
          // fetchItemData();
          // setLoading(false);
        });
    } else {
      // If the user clicks "Cancel", do nothing
      alert("Item deletion canceled!");
    }
  };

  const handleCancelUpdate = () => {
    fetchItemData();
    setShowUpdate(false);
    setShowCard(true);
  };

  const handleCancelDetails = () => {
    setShowDetails(false);
    setShowCard(true);
    fetchItemData();
    setShowUpdate(false);
  };

  const fetchItemData = () => {
    $("#DataTables_Table_0").DataTable();

    const postApiUrl = BASE_URL + `/getAllItemData`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, vendorId: "" }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setItemCard(data.responseMessage);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
        }
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  let i = 1;

  const handleItem = (name, id) => {
    setItemID(id);
    setnewItem(name);
    setShowDetails(true);
    setShowCard(false);
    setShowUpdate(false);
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  const handleImportExcelClick = () => {
    // Create a hidden file input
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".xlsx, .xls"; // Accept only Excel files

    fileInput.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        // Send the file to the API
        const formData = new FormData();
        formData.append("file", file);
        formData.append("uniqueId", decryptedUniqueId);
        setLoading(true);
        try {
          const response = await fetch(BASE_URL + `/importItemsFromExcel`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
            body: formData,
          });

          const data = await response.json(); // Parse the response JSON
          if (data.responseCode === "200" && data.status === "success") {
            alert("Excel file imported succefully"); // Display success message
            fetchItemData(); // Fetch updated data
            setLoading(false);
          } else {
            alert("Failed to upload the file.");
            console.error("Upload failed:", data.responseMessage);
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          alert("Error uploading file.");
        }
      }
    };

    // Trigger the file input click
    fileInput.click();
  };

  const handleSampleFormatClick = () => {
    // URL of the sample Excel file
    const sampleExcelUrl = "/zarpdatainsert.xlsx"; // Replace with your file path
    const link = document.createElement("a");
    link.href = sampleExcelUrl;
    link.download = "sample-file.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="container-fluid">
      {!showUpdate && !showDetails && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Items
            </div>
            <div className="button-container d-flex">
              <button
                className="btn btn-primary px-1 py-2 new-button me-2 mr-1"
                onClick={handleItemsClick}
              >
                + New Item
              </button>
              <button
                className="btn btn-success px-3 py-2 import-button me-2 d-flex align-items-center mr-1"
                onClick={handleImportExcelClick}
                style={{
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                  gap: "8px",
                }}
              >
                <FontAwesomeIcon
                  icon={faFileExcel}
                  style={{ fontSize: "18px" }}
                />
                Import Excel
              </button>
              <button
                className="btn btn-info px-3 py-2 sample-button d-flex align-items-center"
                onClick={handleSampleFormatClick}
                style={{
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                  gap: "8px",
                }}
              >
                <FontAwesomeIcon
                  icon={faFileAlt}
                  style={{ fontSize: "18px" }}
                />
                Sample Format
              </button>
            </div>
          </div>
        </div>
      )}
      {showCard && (
        <div
          className="container-fluid"
          style={{ border: "none", borderRadius: "7px" }}
        >
          <div className="page-content ">
            <Container fluid={true} className="">
              <Row>
                <Col>
                  <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    {itemCards && itemCards.length > 0 ? (
                      <>
                        <div className="body">
                          <div className="table-responsive1">
                            <div
                              id="DataTables_Table_0_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                    id="DataTables_Table_0"
                                    role="grid"
                                    aria-describedby="DataTables_Table_0_info"
                                  >
                                    <thead className="heading-text">
                                      <tr role="row">
                                        <th
                                          className="sorting_asc"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Date: activate to sort column descending"
                                        >
                                          Serial no.
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                        >
                                          Item name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Amount BTC: activate to sort column ascending"
                                        >
                                          Sales price
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="BTC Remaining: activate to sort column ascending"
                                        >
                                          Purchase price
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Usage unit
                                        </th>

                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Type
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Available stock
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        ></th>
                                      </tr>
                                    </thead>
                                    <tbody className="text1">
                                      {itemCards.map((item) => (
                                        <tr
                                          role="row"
                                          className="odd"
                                          key={item.id}
                                        >
                                          <td>{i++}</td>
                                          <td className="sorting_1">
                                            {" "}
                                            <a
                                              href="#"
                                              onClick={() =>
                                                handleItem(item.name, item.id)
                                              }
                                            >
                                              {item.name}
                                            </a>
                                          </td>
                                          <td>₹{item.salesPrice}</td>
                                          <td>₹{item.purchasePrice}</td>
                                          <td>{item.unit}</td>
                                          <td>{item.type}</td>
                                          <td>{item.openingStock}</td>
                                          <td>
                                            <button
                                              className="btn icon-border btn-sm mr-2"
                                              onClick={() =>
                                                handleEdit(item.id)
                                              }
                                            >
                                              <i className="fa fa-edit text1"></i>
                                            </button>
                                            <button
                                              className="btn icon-border btn-sm"
                                              onClick={() =>
                                                handleDelete(item.id)
                                              }
                                            >
                                              <i className="fa fa-trash text1"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-con
                                        trols="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Serial no.
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Item name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Sales price
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        Purchase price
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Usage unit
                                      </th>

                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Type
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                                <h6 className="text-center">
                                  <img
                                    src={NoDataImage}
                                    style={{ width: "50%", height: "50%" }}
                                    alt="No Data Logo"
                                  />{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}

      {showUpdate && (
        <ItemUpdate id={itemId} handleCancle={handleCancelUpdate} />
      )}

      {showDetails && (
        <ItemDetails Itemid={itemId} handleCancle={handleCancelDetails} />
      )}
    </div>
  );
}

export default Items;
