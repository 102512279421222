import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./popup.css";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, endOfMonth, addMonths, format } from "date-fns";
import { Card, Form, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function QuotesUpdate(props) {
  const [row, setRow] = useState([
    {
      itemDetails: "",
      itemCode: "",
      hsnSac: "",
      quantity: "1",
      rate: "0.0",
      make: "",
      discount: "0.0",
      discountType: "₹",
      tax: "",
    },
  ]);

  const handleAdd = () => {
    setUserDetails([
      ...userDetails,
      {
        itemsDetails: "",
        itemCode: "",
        hsnSac: "",
        size: "",
        quantity: "1",
        rate: "0.0",
        make: "",
        discount: "0.0",
        discountType: "",
        tax: "0",
      },
    ]);
  };

  const [bussinessRegistered, setBussinessRegistered] = useState("");
  const [oldQuoteNumber, setOldQuoteNumber] = useState("");
  const [userState, setUserState] = useState([]);
  const [customerState, setCustomerState] = useState("");
  const [discountAmountTds, setDiscountAmountTds] = useState(0.0);
  const [discountAmountTcs, setDiscountAmountTcs] = useState(0.0);
  const [discountCheck, setDiscountCheck] = useState("");
  const [sizeCheck, setSizeCheck] = useState("");
  const [disAllValue, setDisAllValue] = useState("");
  const [discountType, setDiscountType] = useState("₹");
  const [disValue, setDisValue] = useState("");
  const [selectedTaxType, setSelectedTaxType] = useState("");
  const [showing1, setShowing1] = useState(false);
  const [showing2, setShowing2] = useState(false);
  const [selectedTcsOption, setSelectedTcsOption] = useState(null);
  const [selectedTdsOption, setSelectedTdsOption] = useState(null);
  const [tType, setTType] = useState("");
  const [content, setContent] = useState(""); // State to manage editor content
  const [content2, setContent2] = useState("");
  const [subject, setSubject] = useState("");

  const tcs = [
    { value: "5 GST [5%]", label: "5 GST [5%]", discount: 5 },
    { value: "12 GST [12%]", label: "12 GST [12%]", discount: 12 },
    { value: "18 GST [18%]", label: "18 GST [18%]", discount: 18 },
    { value: "28 GST [28%]", label: "28 GST [28%]", discount: 28 },
  ];

  const tds = [
    {
      value: "Commission or Brokerag 5%",
      label: "Commission or Brokerag 5%",
      discount: 5,
    },
    {
      value: "Commission or Brokerag (Reduced) 3.75%",
      label: "Commission or Brokerag (Reduced) 3.75%",
      discount: 3.75,
    },
    { value: "Divident 10%", label: "Divident 10%", discount: 10 },
    {
      value: "Divident (Reduced) 7.5%",
      label: "Divident (Reduced) 7.5%",
      discount: 7.5,
    },
    {
      value: "Other Interest then securities 10%",
      label: "Other Interest then securities 10%",
      discount: 10,
    },
    {
      value: "Other Interest then securities (Reduced) 7.5%",
      label: "Other Interest then securities (Reduced) 7.5%",
      discount: 7.5,
    },
    {
      value: "Payment of Contractors Others 2%",
      label: "Payment of Contractors Others 2%",
      discount: 2,
    },
    {
      value: "Payment of Contractors Others (Reduced) 1.5%",
      label: "Payment of Contractors Others (Reduced) 1.5%",
      discount: 1.5,
    },
    {
      value: "Payment of Contractors Others HUD/Indiv 1%",
      label: "Payment of Contractors Others HUD/Indiv 1%",
      discount: 1,
    },
    {
      value: "Payment of Contractors Others HUD/Indiv (Reduced) 0.75%",
      label: "Payment of Contractors Others HUD/Indiv (Reduced) 0.75%",
      discount: 0.75,
    },
    {
      value: "Professional Fees 10%",
      label: "Professional Fees 10%",
      discount: 10,
    },
    {
      value: "Rent on land or furniture etc 10%",
      label: "Rent on land or furniture etc 10%",
      discount: 10,
    },
  ];

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const userId = localStorage.getItem("userId");
  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userState = data.responseMessage;
          setUserState(userState);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().substr(0, 10)
  );

  function handleUserDetails(e, rowId) {
    const { name, value } = e.target;
    const updatedUserDetails = userDetails.map((data) =>
      data.id === rowId ? { ...data, [name]: value } : data
    );
    setUserDetails(updatedUserDetails);
  }

  const handleDeleteRow = (i, id) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this invoices!"
    );

    if (userConfirmed) {
      const updatedUserDetails = [...userDetails];
      if (userDetails.length === 1) {
        alert("Cannot delete the last remaining invoice.");
        return;
      }
      updatedUserDetails.splice(i, 1);
      setUserDetails(updatedUserDetails); // Update the state with the modified array
    } else {
      alert("Invoice deletion canceled!");
    }
  };
  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      props.handleCancel();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  const [show1, setShow1] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(true);

  const closePopup = () => {
    setShowPopup(false);
  };

  const [selectedTerms, setSelectedTerms] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [dueDateChangedManually, setDueDateChangedManually] = useState(false);

  const updateTotal = (rowData) => {
    let calculatedTotal = 0;
    rowData.forEach((data) => {
      const amount = calculateAmount(data);
      calculatedTotal += parseFloat(amount);
    });
    setTotal(calculatedTotal.toFixed(2));
  };
  const [total, setTotal] = useState(0);

  const calculateAmount = (data, tax) => {
    const { quantity, rate, discount, discountType } = data;
    let amount = quantity * rate;

    if (discount) {
      amount -= discountType === "%" ? (amount * discount) / 100 : discount;
    }

    return amount;
  };

  const [selectedTaxRate, setSelectedTaxRate] = useState(0);

  const calculateTaxAmount = (amount, taxRate) => {
    if (taxRate > 0) {
      let taxIncrease = 0;
      switch (taxRate) {
        case 0.05:
          taxIncrease = 0.5;
          break;
        case 0.12:
          taxIncrease = 1.0;
          break;
        case 0.18:
          taxIncrease = 1.5;
          break;
        case 0.28:
          taxIncrease = 2.0;
          break;
        default:
          break;
      }

      return amount * (taxRate + taxIncrease);
    }
    return 0;
  };

  const calculateSubTotal = () => {
    let subTotal = 0;
    userDetails.forEach((data) => {
      const amount = calculateAmount(data);
      subTotal += parseFloat(amount);
    });
    return subTotal.toFixed(2);
  };

  const [adjustmentValue, setAdjustmentValue] = useState(""); // Step 1: State for adjustment value
  const [oppositeValue, setOppositeValue] = useState(""); // State for opposite value
  const [adjustment, setAdjustment] = useState("");

  useEffect(() => {
    const parsedValue = parseFloat(adjustmentValue);
    if (!isNaN(parsedValue)) {
      const opposite = parsedValue * 1;
      setOppositeValue(opposite.toFixed(2));
    } else {
      setOppositeValue("");
    }
  }, [adjustmentValue]);

  useEffect(() => {
    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage[0].businessRegistered;
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const calculateTotal = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const igst = parseFloat(calculateIGST() || userDetails.igst);
    const adjustment = parseFloat(adjustmentValue || formData.adjustment) || 0;
    const total1 = subTotal + igst + adjustment;
    const total2 = total1 - disValue;
    const totalFinal = total2 + discountAmountTcs;
    return totalFinal.toFixed(2);
  };

  const [formData, setFormData] = useState([]);
  const [fetchedSalesPerson, setFetchedSalesPerson] = useState("");
  const [formDataCustomer, setFormDataCustomer] = useState({
    uniqueId: decryptedUniqueId,
    customerType: "Business",
    status: "Active",
    someName: "Mr.",
    firstName: "",
    lastName: "",
    companyName: "",
    customerName: "",
    email: "",
    workphone: "",
    mobile: "",
    designation: "",
    department: "",
    website: "",
    currency: "INR- Indian Rupee",
    openingBalance: "0",
    paymentTerms: "",
    facebook: "",
    twitter: "",
    billAttention: "",
    billCountry: "India",
    billAddress1: "",
    billAddress2: "",
    billCity: "",
    billState: "Maharashtra",
    billZipcode: "",
    billPhone: "",
    billFax: "",
    shipAttention: "",
    shipCountry: "India",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "Maharashtra",
    shipZipcode: "",
    shipPhone: "",
    shipFax: "",
    gstin: "",
    pan: "",
    placeOfSupply: "[MH] - Maharashtra",
    taxable: "",
    reason: "N/A",
    activeStatus: "Active",
    gstTreatment: "",
    contactPerson: [
      {
        other: "",
        otherFirstname: "",
        otherLastname: "",
        otherEmail: "",
        otherWorkphone: "",
        otherMobile: "",
      },
    ],
  });

  const [userDetails, setUserDetails] = useState([
    {
      itemsDetails: "",
      itemCode: "",
      hsnSac: "",
      size: "",
      quantity: "",
      rate: "",
      make: "",
      discount: "",
      discountType: "%",
      tax: "",
      selectedTaxRate: 0,
      description1: "",
      description2: "",
    },
  ]);

  const [ids, setIds] = useState([]);
  const { invoiceNumber1 } = props;

  const [validationMessage1, setValidationMessage1] = useState("");
  const [invoiceNumberPrefix, setInvoiceNumberPrefix] = useState([]);
  const [invoiceNumberNext, setInvoiceNumberNext] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [manualCheck, setManualCheck] = useState("No");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const insertDataIntoDatabase = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      uniqueId: decryptedUniqueId,
      items: userDetails.map((userDetail) => ({
        itemDetails: userDetail.itemsDetails,
        hsnSac: userDetail.hsnSac,
        itemCode: userDetail.itemCode,
        quantity: userDetail.quantity,
        rate: userDetail.rate,
        make: userDetail.make,
        discount: userDetail.discount,
        discountType: userDetail.discountType,
        tax: userDetail.tax || formData.selectedTaxRate,
        rowDiscount: userDetail.discount,
        amount: calculateAmount(userDetail),
        description1: userDetail.description1 || "",
        description2: userDetail.description2 || "",
        customerTaxType: userState.state === customerState ? 1 : 2,
        cgst:
          userState.state === customerState
            ? (calculateAmount(userDetail) * userDetail.tax) / 100 / 2
            : 0,
        sgst:
          userState.state === customerState
            ? (calculateAmount(userDetail) * userDetail.tax) / 100 / 2
            : 0,
        igst:
          userState.state !== customerState
            ? (calculateAmount(userDetail) * userDetail.tax) / 100
            : 0,
      })),
      ids: ids.map((id) => ({ id })),
      customerName: fetchedCustomerName,
      customerId: selectedCustomerId || formData.customerId,
      quoteNumber: formData.quoteNumber,
      oldInvoiceNumber: oldQuoteNumber,
      quoteDate: dateValue || normalDateFormat(startDate),
      expiryDate: dateValue1 || normalDateFormat(startDate1),
      salesPerson: formData.salesPerson,
      orderNumber: formData.orderNumber,
      subject: subject,
      subtotal: calculateSubTotal(),
      adjustment: formData.adjustment,
      adjustmentLabel: formData.adjustmentLabel,
      total: calculateTotal(),
      overallDiscount: disValue || 0,
      totalDiscount: disAllValue || 0,
      totalDiscountType: discountType,
      taxInAmount: totalTax || 0,
      type: "TCS",
      value: discountAmountTcs || discountAmountTds,
      tcsTax: selectedTcsOption?.value,
      taxCheck: bussinessRegistered || 0,
      sizeCheck: sizeCheck || 0,
      customerNote: formData.customerNote,
      termsAndCondition: content,
      terms: content2,
      discountCheck: discountCheck || 0,
      taxType: selectedTaxType,
      status: "Sent",
      manualCheck: manualCheck,
      gstNumber: formData.gstNumber,
    };

    let newValidationMessage = "";
    if (!formData.customerName) {
      newValidationMessage += "Please select a customer \n";
    }

    setValidationMessage1(newValidationMessage);
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    setIsLoading(true);
    const response = await fetch(BASE_URL + "/updateQuote", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(updatedFormData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Quotation Updated",
          });
          setIsLoading(false);
          props.handleCancel();
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        console.error(error);
      });
  };

  const insertDataIntoDatabaseAsDraft = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      uniqueId: decryptedUniqueId,
      items: userDetails.map((userDetail) => ({
        itemDetails: userDetail.itemsDetails,
        hsnSac: userDetail.hsnSac,
        itemCode: userDetail.itemCode,
        quantity: userDetail.quantity,
        rate: userDetail.rate,
        make: userDetail.make,
        discount: userDetail.discount,
        discountType: userDetail.discountType,
        tax: userDetail.tax || formData.selectedTaxRate,
        rowDiscount: userDetail.discount,
        amount: calculateAmount(userDetail),
        description1: userDetail.description1 || "",
        description2: userDetail.description2 || "",
        customerTaxType: userState.state === customerState ? 1 : 2,
        cgst:
          userState.state === customerState
            ? (calculateAmount(userDetail) * userDetail.tax) / 100 / 2
            : 0,
        sgst:
          userState.state === customerState
            ? (calculateAmount(userDetail) * userDetail.tax) / 100 / 2
            : 0,
        igst:
          userState.state !== customerState
            ? (calculateAmount(userDetail) * userDetail.tax) / 100
            : 0,
      })),
      ids: ids.map((id) => ({ id })),
      customerName: fetchedCustomerName,
      customerId: selectedCustomerId || formData.customerId,
      quoteNumber: formData.quoteNumber,
      oldInvoiceNumber: oldQuoteNumber,
      quoteDate: dateValue || normalDateFormat(startDate),
      expiryDate: dateValue1 || normalDateFormat(startDate1),
      salesPerson: formData.salesPerson,
      orderNumber: formData.orderNumber,
      subject: formData.subject,
      subtotal: calculateSubTotal(),
      adjustment: formData.adjustment,
      adjustmentLabel: formData.adjustmentLabel,
      total: calculateTotal(),
      overallDiscount: disValue || 0,
      totalDiscount: disAllValue || 0,
      totalDiscountType: discountType,
      taxInAmount: totalTax || 0,
      type: "TCS",
      value: discountAmountTcs || discountAmountTds,
      tcsTax: selectedTcsOption?.value,
      taxCheck: bussinessRegistered || 0,
      sizeCheck: sizeCheck || 0,
      customerNote: formData.customerNote,
      termsAndCondition: content,
      terms: content2,
      discountCheck: discountCheck || 0,
      taxType: selectedTaxType,
      status: "Draft",
      manualCheck: manualCheck,
      gstNumber: formData.gstNumber,
    };

    let newValidationMessage = "";
    if (!formData.customerName) {
      newValidationMessage += "Please select a customer \n";
    }

    setValidationMessage1(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    setIsLoading2(true);
    const response = await fetch(BASE_URL + "/updateQuote", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(updatedFormData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Quotation Updated",
          });
          setIsLoading2(false);
          props.handleCancel();
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading2(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        console.error(error);
      });
  };

  useEffect(() => {
    fetch(BASE_URL + `/fetchByQuoteNumber/${invoiceNumber1}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setUserDetails(data.responseMessage);
        const itemIds = data.responseMessage.map((item) => item.id);
        setIds(itemIds);
        setItemDescriptions(
          data.responseMessage.map((item) => ({
            itemDetails: item.itemsDetails,
            description: item.description2 || "",
          }))
        );
        const Data = data.responseMessage[0];
        setOldQuoteNumber(Data.quoteNumber);
        setBussinessRegistered(Data.taxCheck);
        setDiscountCheck(Data.discountCheck);
        setSizeCheck(Data.sizeCheck);
        setContent(Data.termsAndCondition);
        setContent2(Data.terms);
        setSubject(Data.subject);

        setFormData({
          customerName: Data.customerName,
          customerId: Data.customerId,
          quoteNumber: Data.quoteNumber,
          batchNo: Data.batchNo,
          orderNumber: Data.orderNumber,
          quoteDate: Data.quoteDate,
          expiryDate: Data.expiryDate,
          salesPerson: Data.salesPerson,
          customerNote: Data.customerNote,
          adjustment: Data.adjustment,
          adjustmentLabel: Data.adjustmentLabel,
          subtotal: Data.subtotal,
          total: Data.total,
          igst: Data.igst,
          cgst: Data.cgst,
          sgst: Data.sgst,
          termsAndCondition: Data.termsAndCondition,
          terms: Data.terms,
          type: Data.type,
          taxInAmount: Data.taxInAmount,
          tcsTax: Data.tcsTax,
          tdsTax: Data.tdsTax,
          tax: Data.tax,
          value: Data.value,
          gstNumber: Data.gstNumber,
        });
        setSelectedCustomerId(Data.customerId);
        setDisAllValue(Data.totalDiscount);
        setDiscountType(Data.totalDiscountType);
        setFetchedCustomerName(Data.customerName);
        setFetchedSalesPerson(Data.salesPerson);
        setFetchedPaymentTerms(Data.terms);
        // if (Data.tcsTax !== null) {
        const selectedTcsOption = tcs.find(
          (option) => option.value === Data.tcsTax
        );
        console.log("selectedTcsOption : ", selectedTcsOption);
        setSelectedTcsOption(selectedTcsOption);
        setDiscountAmountTcs(parseFloat(Data.value));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);

  const [fetchedCustomerName, setFetchedCustomerName] = useState("");
  const [fetchedPaymentTerms, setFetchedPaymentTerms] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "adjustment") {
      setAdjustmentValue(value);
    }
  };

  const handleSalesInputChange = (event) => {
    const { name, value } = event.target;
    setSalesData({ ...salesData, [name]: value });
  };

  const [showSalesModal, setShowSalesModal] = useState(false);

  const handleSalesClose = () => setShowSalesModal(false);
  const handleSalesShow = () => setShowSalesModal(true);
  const [validationMessage2, setValidationMessage2] = useState("");

  const handleSalesPerson = (event) => {
    let newValidationMessage = "";

    if (!salesData.name) {
      newValidationMessage += "Please enter a name.\n";
    }
    setValidationMessage2(newValidationMessage);
    if (newValidationMessage) {
      return; // Exit function if validation fails
    }
    event.preventDefault();

    fetch(BASE_URL + "/createSalesperson", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(salesData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Sales Person Added Successfully",
          });
          setShowSalesModal(false);
          fetchAllSalesPerson();
          closePopup();
        } else {
          swal({
            icon: "error",
            title: "Failed To Create Tax Rate",
          });
          setShowSalesModal(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occured While Creating Tax RAte ",
        });
        setShowSalesModal(true);
      });
  };

  const [salesData, setSalesData] = useState({
    uniqueId: decryptedUniqueId,
    name: "",
    email: "",
  });

  const handleInputChangess = (e, i) => {
    const { name, value } = e.target;
    const updatedUserDetails = [...userDetails];
    updatedUserDetails[i][name] = value;
    setUserDetails(updatedUserDetails);
    if (name === "tax") {
      const taxRate = parseFloat(value);
      setSelectedTaxRate(taxRate);
    }
  };

  const [salesPersonData, setSalesPersonData] = useState([]);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState("");

  const handleSalesPersonChange = (value, id) => {
    const selectedValue = value;
    setSelectedSalesPerson(selectedValue);
    setFetchedSalesPerson(selectedValue);
    setFormData({ ...formData, salesPerson: selectedValue });
  };

  const fetchAllSalesPerson = () => {
    fetch(BASE_URL + `/getAllSalesPersonByUniqueId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const filteredData = data.responseMessage.filter(
            (item) => item.name !== "Others"
          );
          setSalesPersonData(filteredData);
        } else {
          console.error("Failed to fetch salesperson:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching salesperson:", error);
      });
  };
  useEffect(() => {
    fetchAllSalesPerson();
  }, []);

  const [custNames, setCustNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(""); // State to store the selected customer's ID
  const handleCustomerChange = (selectedOption) => {
    const selectedValue = selectedOption.value; // Assuming selectedOption has a 'value' property
    setSelectedCustomer(selectedValue);
    setFetchedCustomerName(selectedValue);

    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );

    if (selectedCustomerObject) {
      setFormData({
        ...formData,
        customerName: selectedValue,
        customerId: selectedCustomerObject.id,
      });
      const customerState = selectedCustomerObject.billState;
      setCustomerState(customerState);
    } else {
      console.log("Selected Customer: (Not found)");
    }
  };

  const handleTaxChange = (e, rowIndex) => {
    const { value } = e.target;
    const updatedUserDetails = [...userDetails];
    updatedUserDetails[rowIndex].tax = value;
    updatedUserDetails[rowIndex].selectedTaxRate = parseFloat(value);
    setUserDetails(updatedUserDetails);
  };

  const calculateIGST = () => {
    let totalIGST = 0;

    userDetails.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      if (taxRate > 0) {
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = (parseFloat(amount) * taxRate) / 100;
        totalIGST += increasedTaxAmount;
      }
    });
    return totalIGST.toFixed(2);
  };

  const calculateIGSTForTax = () => {
    const taxResults = [];

    userDetails.forEach((data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate > 0) {
        let existingTaxResult = taxResults.find(
          (result) => result.taxRate === taxRate
        );
        if (!existingTaxResult) {
          existingTaxResult = { taxRate, totalIGST: 0 };
          taxResults.push(existingTaxResult);
        }
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = amount * taxRate;
        existingTaxResult.totalIGST += increasedTaxAmount;
      }
    });
    return taxResults;
  };

  const calculateGroupedSGSTandCGST = () => {
    const taxGroups = userDetails.reduce((acc, data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate === 0) return acc; // Skip 0% GST
      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = amount * taxRate;
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;

      if (!acc[taxRate]) {
        acc[taxRate] = { totalSGST: 0, totalCGST: 0 };
      }

      acc[taxRate].totalSGST += sgst;
      acc[taxRate].totalCGST += cgst;

      return acc;
    }, {});

    return taxGroups;
  };

  const calculateSGSTandCGST = () => {
    let totalSGST = 0;
    let totalCGST = 0;
    userDetails.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = parseFloat(amount) - amount / (1 + taxRate);
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;
      totalSGST += sgst;
      totalCGST += cgst;
    });
    return {
      totalSGST: totalSGST.toFixed(2),
      totalCGST: totalCGST.toFixed(2),
    };
  };

  const totalTax =
    calculateIGST() ||
    calculateSGSTandCGST().totalSGST + calculateSGSTandCGST().totalCGST;

  const [itemName, setItemName] = useState("");
  const [showItemModal, setShowItemModal] = useState(false);

  const [itemData, setItemData] = useState({
    uniqueId: decryptedUniqueId,
    description: "",
  });

  const handleItemClose = () => {
    setShowItemModal(false);
    setItemData({
      ...itemData,
      description: "",
    });
  };

  const handleItemChange = (event) => {
    const { name, value } = event.target;
    setItemData({ ...itemData, [name]: value });
  };

  const [itemDescriptions, setItemDescriptions] = useState([]);

  const handleItem = (event) => {
    event.preventDefault();

    const newItemDescription = {
      itemDetails: itemName,
      description: itemData.description,
    };

    setItemDescriptions((prevDescriptions) => {
      const existingIndex = prevDescriptions.findIndex(
        (desc) => desc.itemDetails === itemName
      );

      if (existingIndex !== -1) {
        const updatedDescriptions = [...prevDescriptions];
        updatedDescriptions[existingIndex] = newItemDescription;
        return updatedDescriptions;
      } else {
        return [...prevDescriptions, newItemDescription];
      }
    });

    setUserDetails((prevRows) => {
      return prevRows.map((row) =>
        row.itemsDetails === itemName
          ? { ...row, description2: itemData.description }
          : row
      );
    });

    setShowItemModal(false);
    closePopup();
  };

  const handleItemShow = (itemsDetails) => {
    const itemDescription = itemDescriptions.find(
      (desc) => desc.itemDetails === itemsDetails
    );
    if (itemDescription) {
      setItemData({ description: itemDescription.description });
    } else {
      setItemData({ description: "" });
    }
    setItemName(itemsDetails);
    setShowItemModal(true);
  };

  const [dropdownData, setDropdownData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const fetchItemData = () => {
    const postApiUrl = BASE_URL + `/getAllItemData`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, vendorId: "" }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseMessage;
        setDropdownData(responseData.map((item) => item));
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  const [searchIndex, setSearchIndex] = useState(0);

  useEffect(() => {
    const results = dropdownData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, dropdownData]);

  const handleSearchChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...userDetails];
    newRow[index].itemsDetails = value;
    setUserDetails(newRow);
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setSearchIndex(index);
  };

  const handleItemDetailsChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...userDetails];
    newRow[index].itemsDetails = value;
    setUserDetails(newRow);
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setSearchIndex(index);
    if (value) {
      fetchItemRate(value, index);
    } else {
      handleSearchChange(value, index);
    }
  };

  const handleItemSelect = (selectedItem, selectedItemId, index) => {
    setSearchResults([]);
    const newRow = [...userDetails];
    newRow[index].itemsDetails = selectedItem;
    setUserDetails(newRow);
    fetchItemRate(selectedItemId, index);
  };

  const fetchItemRate = (itemId, index) => {
    const getApiUrl = BASE_URL + `/getItemData/${itemId}`;

    fetch(getApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const rate = data.data.taxableAmount;
        const hsnSac = data.data.hsnCode;
        const description1 = data.data.description;
        const itemName = data.data.name;
        const tax = data.data.intraStateTaxRate;
        const updatedRow = [...userDetails];
        updatedRow[index].rate = rate;
        updatedRow[index].description1 = description1;
        updatedRow[index].hsnSac = hsnSac;
        updatedRow[index].itemsDetails = itemName;
        updatedRow[index].tax = tax;
        setUserDetails(updatedRow);
        setSearchResults([]);
        // handleAdd();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  function convertDateStringToDate(dateString) {
    if (!dateString) {
      return null;
    }
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }

  const dateObject = convertDateStringToDate(formData.invoiceDate);

  const [showDueDate, setShowDueDate] = useState(true);
  const [showInvDate, setShowInvDate] = useState(true);
  const [showDuePerTerm, setShowDuePerTerm] = useState(false);

  const updateDueDate = (date, terms) => {
    setDueDateChangedManually(false);
    if (terms.startsWith("Net ")) {
      const daysToAdd = parseInt(terms.replace("Net ", ""));
      setDueDate(
        normalDateFormat(
          addDays(showDuePerTerm == true ? startDate : dateObject, daysToAdd)
        )
      );
      setShowDueDate(true);
    } else if (terms === "Due End Of The Month") {
      setDueDate(
        normalDateFormat(
          endOfMonth(showDuePerTerm == true ? startDate : dateObject)
        )
      );
      setShowDueDate(true);
    } else if (terms === "Due End Of Next Month") {
      setDueDate(
        normalDateFormat(
          endOfMonth(
            addMonths(showDuePerTerm == true ? startDate : dateObject, 1)
          )
        )
      );
      setShowDueDate(true);
    } else if (terms === "Due On Receipt") {
      setDueDate(normalDateFormat(startDate));
      setShowDueDate(true);
    } else if (terms === "Custom") {
      setShowDueDate(false);
    } else {
      setDueDate("");
    }
  };

  useEffect(() => {
    if (currentDate && selectedTerms && !dueDateChangedManually) {
      updateDueDate(new Date(currentDate), selectedTerms);
    }
  }, [currentDate, selectedTerms, dueDateChangedManually]);

  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [dateValue, setDateValue] = useState("");
  const [dateValue1, setDateValue1] = useState("");

  useEffect(() => {
    setStartDate(new Date());
    setStartDate1(new Date());
  }, []);

  useEffect(() => {
    setStartDate(new Date());
    setStartDate1(new Date());
  }, []);

  const handleDate = (date) => {
    setStartDate(date);
    let normalFormat = date ? normalDateFormat(date) : "";
    setDateValue(normalFormat);
  };

  const handleDate1 = (date) => {
    setStartDate1(date);
    let normalFormat = date ? normalDateFormat(date) : "";
    setDateValue1(normalFormat);
  };

  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return "";
  };

  const optionsCustomers = custNames.map((customer) => ({
    value: customer.customerName,
    label: customer.customerName,
  }));

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormDataCustomer((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTdsOptionChange = (selectedOption) => {
    setSelectedTdsOption(selectedOption);
    const subtotal = calculateSubTotal();
    setFormData({ ...formData, tdsTax: selectedOption.value });

    if (selectedOption && selectedOption.discount) {
      const discountedTotal = applyDiscount(subtotal, selectedOption.discount);
    }
  };

  const handleTcsOptionChange = (selectedOption) => {
    setSelectedTcsOption(selectedOption);
    const subtotal = calculateSubTotal();
    setFormData({ ...formData, tcsTax: selectedOption });

    // Check if a TCS option is selected and has a discount
    if (selectedOption && selectedOption.discount) {
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedOption.discount
      );
    }
  };

  const applyDiscountTcs = (subtotal, discountPercentage) => {
    const discountAmount = (subtotal * discountPercentage) / 100;
    const tcsDis = (subtotal - discountAmount).toFixed(2);
    setDiscountAmountTcs(parseFloat(discountAmount.toFixed(2))); // Set discountAmount in state

    return tcsDis;
  };

  const calculateTotal2 = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const igst = parseFloat(calculateIGST());
    const adjustment = parseFloat(adjustmentValue) || 0; // Use the adjustment value if it's a valid number, otherwise, default to 0
    const total1 = subTotal + igst + adjustment;
    const total = total1 - disValue;
    const totalFinal = discountAmountTds + total;
    return totalFinal.toFixed(2);
  };

  const applyDiscount = (subtotal, discountPercentage) => {
    const discountAmount = (subtotal * discountPercentage) / 100;
    const tdsDis = (subtotal - discountAmount).toFixed(2);
    setDiscountAmountTds(parseFloat(discountAmount.toFixed(2))); // Set discountAmount in state

    return tdsDis;
  };

  const calculateDiscount = () => {
    const amount = parseFloat(calculateSubTotal()).toFixed(2);

    if (discountType === "₹") {
      setDisValue(disAllValue);
    } else if (discountType === "%") {
      const percentageDiscount = (amount * disAllValue) / 100;
      setDisValue(percentageDiscount.toFixed(2));
    }
  };

  const handleDisAllValueChange = (e) => {
    setDisAllValue(e.target.value);
  };

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
  };

  const handleRadioClick = (taxType) => {
    setSelectedTaxType(taxType);
    setFormData({ ...formData, type: taxType });

    if (taxType === "TDS") {
      setSelectedTcsOption(null);
      setDiscountAmountTds(0);
      setDiscountAmountTcs(0);
      setTType("TDS");
    } else if (taxType === "TCS") {
      setSelectedTdsOption(null);
      setDiscountAmountTcs(0);
      setDiscountAmountTds(0);
      setTType("TCS");
    }

    setShowing1(taxType === "TDS");
    setShowing2(taxType === "TCS");
  };

  useEffect(() => {
    calculateDiscount();
  }, [disAllValue, discountType]);

  const handlePaymentChange = (selectedOption) => {
    setSelectedTerms(selectedOption.value);
    setFetchedPaymentTerms(selectedOption.value);
    updateDueDate(dateValue, selectedOption.value);
  };

  const optionSales = salesPersonData.map((person) => ({
    value: person.name,
    label: person.name,
  }));

  const customStyles2 = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      background: "#F6F6F6",
      borderRadius: "10px",
      // minHeight: "40px",
      height: "46px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginLeft: "8px",
      height: "46px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      // height: "40px",
    }),
  };

  const customStyles3 = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      background: "#F6F6F6",
      borderRadius: "10px",
      // minHeight: "40px",
      height: "20px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginLeft: "8px",
      height: "20px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      // height: "40px",
    }),
  };
  const [validationMessage, setValidationMessage] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSaveClick = () => {
    let newValidationMessage = "";

    if (!formDataCustomer.customerName) {
      newValidationMessage += "Please Select a Customer display name.\n";
    } else if (
      formData.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
    ) {
      newValidationMessage += "Please enter a valid email address.\n";
    }

    // if (!formData.email) {
    //   newValidationMessage += "Please enter an email address.\n";
    // } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
    //   newValidationMessage += "Please enter a valid email address.\n";
    // }

    setValidationMessage(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      // alert(newValidationMessage.trim()); // Trim trailing newline for better formatting
      return; // Exit function if validation fails
    }
    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + "/createCustomer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formDataCustomer),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          swal({
            icon: "success",
            title: "Customer Added Successfully",
            text: "",
          });
          setShow(false);
          fetchAllCustomers();
        } else if (data.responseCode === "400") {
          swal({
            icon: "error",
            title: "This email already exists, please insert another email.",
            text: "",
          });
          setShow(true);
        } else {
          swal({
            icon: "error",
            title: "Failed to post customer data",
            text: "",
          });
          setShow(true);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        setShow(true);
      });
  };

  const generateNamePairs = () => {
    const { someName, firstName, lastName, companyName } = formDataCustomer;
    const pairs = [];

    if (firstName && lastName) {
      pairs.push(`${someName} ${firstName} ${lastName}`);
      pairs.push(`${firstName} ${lastName}`);
      pairs.push(`${lastName}, ${firstName}`);
      pairs.push(`${companyName}`);
    }

    return pairs;
  };

  useEffect(() => {
    if (
      (selectedTaxType === "TDS" || formData.type === "TDS") &&
      selectedTdsOption &&
      selectedTdsOption.discount
    ) {
      const subtotal = calculateSubTotal();
      const discountedTotal = applyDiscount(
        subtotal,
        selectedTdsOption.discount
      );
    } else if (
      (selectedTaxType === "TCS" || formData.type === "TCS") &&
      selectedTcsOption &&
      selectedTcsOption.discount
    ) {
      const subtotal = calculateSubTotal();
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedTcsOption.discount
      );
    }
  }, [userDetails]);

  const setBill = () => {
    setShow1(true);
    fetchBill();
  };

  const copyAdd = () => {
    if (show1) {
      // If auto-generating is selected, set the generatedInvoiceNumber
      setInvoiceNumber(invoiceNumberPrefix + invoiceNumberNext);
      setFormData({
        ...formData,
        quoteNumber: invoiceNumberPrefix + invoiceNumberNext,
      });
      setManualCheck("No");
    } else {
      // If manually adding is selected, set it to null
      setInvoiceNumber("");
      setFormData({
        ...formData,
        quoteNumber: "",
      });
      setManualCheck("Yes");
    }
  };

  const fetchBill = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Prepare the data to send in the POST request
    const requestData = {
      // Include any other data you need to send in the request
      uniqueId: decryptedUniqueId,
      type: "quote",
    };

    // Define the POST request options
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
        // Add any other headers if required
      },
      body: JSON.stringify(requestData), // Convert the data to JSON format
    };

    // Make the POST request to your API endpoint
    fetch(BASE_URL + `/getCurruntNumberList2`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setInvoiceNumberPrefix(data.responseMessage.prefix);
        setInvoiceNumberNext(data.responseMessage.numbers);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  const fetchAllCustomers = () => {
    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };
  useEffect(() => {
    fetchAllCustomers();
  }, []);

  useEffect(() => {
    if (fetchedCustomerName) {
      const selectedCustomerObject = custNames.find(
        (person) => person.customerName === fetchedCustomerName
      );

      if (selectedCustomerObject) {
        const customerState = selectedCustomerObject.billState;
        setCustomerState(customerState);
      }
    }
  }, [fetchedCustomerName, custNames]);
  const handleEditorChange = (value) => {
    setContent(value); // Update the state as content changes
  };

  const handleEditorChange2 = (value) => {
    setContent2(value);
  };
  const handleEditorChange3 = (value) => {
    setSubject(value);
  };

  return (
    <div>
      <Row>
        <Col>
          <div className="my-3">
            <div className="d-flex align-items-center ml-1 mt-4">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancleClick}
                  className="arrow-child"
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5
                  className="ml-1 mb-0"
                  style={{ letterSpacing: "1px", color: "#086bd5de" }}
                >
                  Update Quote
                </h5>
              </div>
            </div>
          </div>
          {validationMessage1 && (
            <Alert severity="error" className="mt-0">
              {validationMessage1.split("\n").map((message, index) => (
                <div key={index}>{message}</div>
              ))}
            </Alert>
          )}{" "}
          <Card className="card-style p-4">
            {/* <Card.Body> */}
            <Row>
              <Col className="" md="3">
                <Form.Group>
                  <label
                    className="text-primary"
                    style={{ fontSize: 16, fontWeight: "600" }}
                  >
                    CUSTOMER INFO
                  </label>
                </Form.Group>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col className="" md="3">
                  <Form.Group>
                    <label
                      className="label-title"
                      style={{ fontSize: "14px", color: "grey" }}
                    >
                      Customer Name <span className="valid"> *</span>
                    </label>
                    <Select
                      // className="dropdown-field"
                      value={optionsCustomers.find(
                        (option) => option.value === fetchedCustomerName
                      )}
                      options={optionsCustomers}
                      onChange={(selectedOption) =>
                        handleCustomerChange(selectedOption)
                      }
                      placeholder="Select Customer"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",
                          borderRadius: "7px",
                          height: "46px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          height: "19px",
                        }),
                      }}
                    />
                    <button type="button" class="btn p-0" onClick={handleShow}>
                      <span
                        class="text-primary"
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        New Customer
                      </span>
                    </button>

                    <Modal size="lg" show={show} onHide={handleClose}>
                      <div class="modal-header d-flex align-items-center pb-2 pt-2">
                        <h5 class="modal-title fs-5" id="newcustomerPopup">
                          New Customer
                        </h5>
                        <button
                          type="button"
                          className="btn pr-0"
                          onClick={handleClose}
                        >
                          <ion-icon
                            name="close-circle-outline"
                            size="large"
                            style={{ width: "28px" }}
                          ></ion-icon>
                        </button>
                      </div>
                      <Modal.Body className="p-0 pb-3">
                        {validationMessage && (
                          <Alert severity="error" className="mt-0">
                            {validationMessage
                              .split("\n")
                              .map((message, index) => (
                                <div key={index}>{message}</div>
                              ))}
                          </Alert>
                        )}{" "}
                        {/* <div className="container">
                          <div className="row d-inline-flex">
                            <div className="modalinputchild">
                              <div className="text-style"> Salutation </div>
                              <select
                                className="form-control"
                                name="someName"
                                value={formData.someName}
                                onChange={handleChange}
                                style={{
                                  borderRadius: "9px",
                                  backgroundColor: "#f5f5f5",
                                  width: "200px",
                                  height: "35px",
                                  fontSize: "13px",
                                  marginTop: "10px",
                                }}
                              >
                                <option value="Mr.">Mr.</option>
                                <option value="Mrs.">Mrs.</option>
                                <option value="Ms.">Ms.</option>
                                <option value="Miss.">Miss.</option>
                                <option value="Dr.">Dr.</option>
                              </select>
                            </div>
                            <div className="modalinputchild">
                              <div className="text-style">First Name</div>
                              <input
                                className="form-control"
                                placeholder="First Name"
                                name="firstName"
                                id="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                type="text"
                              />
                            </div>
                            <div className="modalinputchild">
                              <div className="text-style">Last Name</div>
                              <input
                                className="form-control"
                                placeholder="Last Name"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="row d-inline-flex pt-2">
                            <div className="modalinputchild">
                              <div className="text-style">
                                {" "}
                                Customer Email{" "}
                                <span style={{ color: "red" }}> *</span>
                              </div>
                              <input
                                className="form-control"
                                placeholder="Customer Email"
                                name="email"
                                type="text"
                                value={formData.email}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="modalinputchild">
                              <div className="text-style">
                                {" "}
                                Customer Display Name{" "}
                                <span style={{ color: "red" }}> *</span>
                              </div>
                              <input
                                className="form-control"
                                placeholder="Customer Display Name"
                                list="customerNames"
                                name="customerName"
                                value={formData.customerName}
                                onChange={handleChange}
                                style={{
                                  borderRadius: "7px",
                                  backgroundColor: "#f5f5f5",
                                  width: "200px",
                                  height: "40px",
                                  fontSize: "13px",
                                  marginTop: "10px",
                                }}
                              />
                              <datalist id="customerNames">
                                {generateNamePairs().map((pair, index) => (
                                  <option key={index} value={pair} />
                                ))}
                              </datalist>{" "}
                            </div>
                            <div className="modalinputchild">
                              <div className="text-style">Company Name</div>
                              <input
                                className="form-control"
                                placeholder="Add company name"
                                name="companyName"
                                type="text"
                                value={formData.companyName}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="row d-inline-flex pt-2">
                            <div className="modalinputchild">
                              <div className="text-style">Customer Phone</div>
                              <input
                                className="form-control"
                                placeholder="Work Phone"
                                name="workphone"
                                type="text"
                                value={formData.workphone}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="modalinputchild">
                              <div className="text-style">Mobile</div>
                              <input
                                className="form-control"
                                placeholder="Mobile"
                                name="mobile"
                                type="text"
                                value={formData.mobile}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="container">
                          <div className="row d-inline-flex">
                            <div className="modalinputchild">
                              <div className="text-style"> Salutation </div>
                              <select
                                className="form-control"
                                name="someName"
                                value={formDataCustomer.someName}
                                onChange={handleChange}
                                style={{
                                  borderRadius: "9px",
                                  backgroundColor: "#f5f5f5",
                                  width: "200px",
                                  height: "35px",
                                  fontSize: "13px",
                                  marginTop: "10px",
                                }}
                              >
                                <option value="Mr.">Mr.</option>
                                <option value="Mrs.">Mrs.</option>
                                <option value="Ms.">Ms.</option>
                                <option value="Miss.">Miss.</option>
                                <option value="Dr.">Dr.</option>
                              </select>
                            </div>
                            <div className="modalinputchild">
                              <div className="text-style">First Name</div>
                              <input
                                className="form-control"
                                placeholder="First Name"
                                name="firstName"
                                id="firstName"
                                value={formDataCustomer.firstName}
                                onChange={handleChange}
                                type="text"
                              />
                            </div>
                            <div className="modalinputchild">
                              <div className="text-style">Last Name</div>
                              <input
                                className="form-control"
                                placeholder="Last Name"
                                id="lastName"
                                name="lastName"
                                value={formDataCustomer.lastName}
                                onChange={handleChange}
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="row d-inline-flex pt-2">
                            <div className="modalinputchild">
                              <div className="text-style"> Customer Email </div>
                              <input
                                className="form-control"
                                placeholder="Customer Email"
                                name="email"
                                type="text"
                                value={formDataCustomer.email}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="modalinputchild">
                              <div className="text-style">
                                {" "}
                                Customer Display Name{" "}
                                <span style={{ color: "red" }}> *</span>
                              </div>
                              <input
                                className="form-control"
                                placeholder="Customer Display Name"
                                list="customerNames"
                                name="customerName"
                                value={formDataCustomer.customerName}
                                onChange={handleChange}
                                style={{
                                  borderRadius: "7px",
                                  backgroundColor: "#f5f5f5",
                                  width: "200px",
                                  height: "40px",
                                  fontSize: "13px",
                                  marginTop: "10px",
                                }}
                              />
                              <datalist id="customerNames">
                                {generateNamePairs().map((pair, index) => (
                                  <option key={index} value={pair} />
                                ))}
                              </datalist>
                            </div>
                            <div className="modalinputchild">
                              <div className="text-style">Company Name</div>
                              <input
                                className="form-control"
                                placeholder="Add company name"
                                name="companyName"
                                type="text"
                                value={formDataCustomer.companyName}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="row d-inline-flex pt-2">
                            <div className="modalinputchild">
                              <div className="text-style">Customer Phone</div>
                              <input
                                className="form-control"
                                placeholder="Work Phone"
                                name="workphone"
                                type="text"
                                value={formDataCustomer.workphone}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="modalinputchild">
                              <div className="text-style">Mobile</div>
                              <input
                                className="form-control"
                                placeholder="Mobile"
                                name="mobile"
                                type="text"
                                value={formDataCustomer.mobile}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="p-2">
                        <div className="modal-bottombtns">
                          <button
                            type="button"
                            class="modalbtn-1 btn mt-0"
                            onClick={handleClose}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="modalbtn-3 btn btn-primary"
                            onClick={handleSaveClick}
                          >
                            Create Customer
                          </button>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </Form.Group>
                </Col>

                <Col className="" md="3">
                  <Form.Group>
                    <label
                      className=""
                      style={{ fontSize: "14px", color: "grey" }}
                    >
                      Quote Number <span className="valid"> *</span>
                    </label>
                    <Form.Control
                      placeholder="Quote Number"
                      type="text"
                      name="quoteNumber"
                      value={formData.quoteNumber}
                      onChange={(e) => {
                        setManualCheck("Yes");
                        setFormData({
                          ...formData,
                          quoteNumber: e.target.value,
                        });
                      }}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        color: "black",
                        height: "38px",
                      }}
                    />
                    <button
                      type="button"
                      className="btn p-0"
                      data-bs-toggle="modal"
                      data-bs-target="#generatePop"
                    >
                      <span
                        className="text-primary"
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        Generate
                      </span>
                    </button>

                    {/* Invoice generate popup*/}
                    <div
                      className="modal fade"
                      id="generatePop"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header d-flex align-items-center pb-2 pt-2">
                            <h5
                              className="modal-title fs-5"
                              id="generateinvoiceHead"
                            >
                              Quote Number
                            </h5>
                            <button
                              type="button"
                              className="btn pr-0"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <ion-icon
                                name="close-circle-outline"
                                size="large"
                                style={{ width: "28px" }}
                              ></ion-icon>
                            </button>
                          </div>
                          <div className="modal-body pt-1 pb-1">
                            <div className="popupinvoice-container ml-2 mt-2 mr-2 mb-0">
                              <div className="row headline">
                                <div className="customer-headline-label">
                                  <label className="form-label">
                                    <span className="headline">
                                      Your invoice numbers are set on
                                      auto-generate mode to save your time.
                                      <br /> Are you sure about changing this
                                      setting?
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <label className="customer-rdiobox-label d-flex align-items-center">
                                  <input
                                    className="mr-2"
                                    type="radio"
                                    name="cus_type"
                                    id="cus_bus"
                                    value="Business"
                                    checked={show1 === true ? true : false}
                                    onClick={setBill}
                                  />
                                  <span className="customer-option">
                                    Continue auto-generating invoice numbers
                                  </span>
                                </label>
                              </div>
                              {show1 && (
                                <div className="row ml-3 mb-3">
                                  <div className="customer-generate-inputfield">
                                    <span>Prefix</span>
                                    <input
                                      className="form-control mt-1"
                                      placeholder="Prefix"
                                      value={invoiceNumberPrefix}
                                      onChange={(e) =>
                                        setInvoiceNumberPrefix(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="customer-generate-inputfield2">
                                    <span>Next Number</span>
                                    <input
                                      className="form-control mt-1"
                                      placeholder="Next Number"
                                      value={invoiceNumberNext}
                                      onChange={(e) =>
                                        setInvoiceNumberNext(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="row mt-1">
                                <label className="customer-rdiobox-label d-flex align-items-center">
                                  <input
                                    className="mr-2"
                                    type="radio"
                                    name="cus_type"
                                    id="cus_bus"
                                    value="Business"
                                    checked={show1 === false ? true : false}
                                    onClick={() => {
                                      setShow1(false);
                                    }}
                                  />
                                  <span className="customer-option">
                                    I will add them manually each time
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <div className="modal-bottombtns">
                              <button
                                type="button"
                                class="modalbtn-1 btn mt-0"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                class="modalbtn-3 btn btn-primary"
                                onClick={() => {
                                  copyAdd();
                                }}
                                data-bs-dismiss="modal"
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                </Col>

                <Col className="" md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Reference
                    </label>
                    <Form.Control
                      placeholder="Reference"
                      name="orderNumber"
                      value={formData.orderNumber}
                      onChange={handleInputChange}
                      type="text"
                      style={{
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        padding: "12px",
                        color: "black",
                        height: "38px",
                        "::placeholder": {
                          color: "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                        },
                      }}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Quote Date <span className="valid"> *</span>
                    </label>
                    <div>
                      {showInvDate ? (
                        <input
                          className="form-control"
                          type="text"
                          value={dueDate || formData.quoteDate}
                          onClick={(e) => {
                            setShowInvDate(false);
                            setFormData({
                              ...formData,
                              quoteDate: normalDateFormat(startDate),
                            });
                            handleDate(startDate);
                            setShowDuePerTerm(true);
                          }}
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            padding: "12px",
                            height: "38px",
                            width: "260.8px",
                          }}
                          readOnly
                          showIcon
                          toggleCalendarOnIconClick
                        />
                      ) : (
                        <div className="customDatePickerWidth">
                          <DatePicker
                            className="form-control"
                            selected={startDate}
                            dateFormat="dd-MM-yyyy"
                            showIcon
                            toggleCalendarOnIconClick
                            onChange={(date) => handleDate(date)}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Expiry Date <span className="valid"> *</span>
                    </label>
                    <div>
                      {showDueDate ? (
                        <input
                          className="form-control"
                          type="text"
                          value={dueDate || formData.expiryDate}
                          onClick={(e) => {
                            setShowDueDate(false);
                            setSelectedTerms("Custom");
                            setFormData({ ...formData, terms: "Custom" });
                          }}
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            padding: "12px",
                            height: "38px",
                            width: "260.8px",
                          }}
                          readOnly
                          showIcon
                          toggleCalendarOnIconClick
                        />
                      ) : (
                        <div className="customDatePickerWidth">
                          <DatePicker
                            className="form-control"
                            selected={startDate1}
                            dateFormat="dd-MM-yyyy"
                            showIcon
                            toggleCalendarOnIconClick
                            onChange={(date) => handleDate1(date)}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Batch No.
                    </label>
                    <Form.Control
                      placeholder="Batch No."
                      name="batchNo"
                      value={formData.batchNo}
                      readOnly
                      type="text"
                      style={{
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        padding: "12px",
                        color: "black",
                        height: "38px",
                        "::placeholder": {
                          color: "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                        },
                      }}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Sales Person
                    </label>
                    <Select
                      value={optionSales.find(
                        (option) => option.value === fetchedSalesPerson
                      )}
                      options={optionSales}
                      onChange={(selectedOption) =>
                        handleSalesPersonChange(
                          selectedOption.label,
                          selectedOption.value
                        )
                      }
                      placeholder="Select a SalesPerson"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",
                          borderRadius: "7px",
                          height: "46px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          height: "19px",
                        }),
                      }}
                    />
                    <button
                      type="button"
                      class="btn p-0"
                      onClick={handleSalesShow}
                    >
                      <span
                        class="text-primary"
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        New Sales Person
                      </span>
                    </button>
                    <Modal show={showSalesModal} onHide={handleSalesClose}>
                      <div class="modal-header d-flex align-items-center pb-2 pt-2">
                        <h5 class="modal-title fs-5" id="salespersonHead">
                          Add Sales Person
                        </h5>
                        <button
                          type="button"
                          className="btn pr-0"
                          onClick={handleSalesClose}
                        >
                          <ion-icon
                            name="close-circle-outline"
                            size="large"
                            style={{ width: "28px" }}
                          ></ion-icon>
                        </button>
                      </div>
                      <Modal.Body>
                        {validationMessage2 && (
                          <Alert severity="error" className="mt-0">
                            {validationMessage2
                              .split("\n")
                              .map((message, index) => (
                                <div key={index}>{message}</div>
                              ))}
                          </Alert>
                        )}{" "}
                        <div className="row pt-2">
                          <div className="modalsalesinput">
                            <label className="form-label">
                              <span style={{ color: "red" }}>Name *</span>{" "}
                            </label>
                            <input
                              className="form-control"
                              placeholder="Add Full Name"
                              id="name"
                              name="name"
                              type="text"
                              value={salesData.name}
                              onChange={handleSalesInputChange}
                            />
                          </div>
                        </div>
                        <div className="row pt-4">
                          <div className="modalsalesinput">
                            <label className="form-label">
                              <span style={{ color: "#0b0a0ab5" }}>Email</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Add Email Address"
                              id="email"
                              name="email"
                              type="text"
                              value={salesData.email}
                              onChange={handleSalesInputChange}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="p-2">
                        <div className="modal-bottombtns">
                          <button
                            type="button"
                            class="modalbtn-1 btn mt-0"
                            onClick={handleSalesClose}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="modalbtn-3 btn btn-primary"
                            onClick={handleSalesPerson}
                            data-bs-dismiss="modal"
                          >
                            Create Sales Person
                          </button>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Form.Group>
                    <label
                      className=""
                      style={{ fontSize: "14px", color: "grey" }}
                    >
                      Subject
                    </label>
                    {/* <Editor
                      value={subject}
                      apiKey="4h8oo2lv4rl9gi28mb7hv90bwsf4k369lnf0gmxragfpofwj"
                      init={{
                        branding: false,
                        height: 300,
                        menubar: true,
                        plugins:
                          "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                        toolbar:
                          "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                        image_advtab: true,
                      }}
                      onEditorChange={handleEditorChange3} // Event to capture editor content
                    /> */}
                    <ReactQuill
                      onChange={handleEditorChange3} // Function to update state
                      theme="snow" // Use "bubble" for a minimal UI
                      style={{ height: "300px", marginBottom: "50px" }}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
                          [{ font: [] }], // Font options
                          [{ size: [] }], // Font size
                          [{ color: [] }, { background: [] }], // Text color & background
                          ["bold", "italic", "underline", "strike"], // Basic text styles
                          [{ script: "sub" }, { script: "super" }], // Subscript & superscript
                          [{ align: [] }], // Text alignment
                          [{ list: "ordered" }, { list: "bullet" }], // Lists
                          [{ indent: "-1" }, { indent: "+1" }], // Indentation
                          ["blockquote", "code-block"], // Blockquote & code block
                          ["link", "image", "video"], // Media links
                          ["clean"], // Remove formatting
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "color",
                        "background",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "script",
                        "align",
                        "list",
                        "bullet",
                        "indent",
                        "blockquote",
                        "code-block",
                        "link",
                        "image",
                        "video",
                      ]}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3" md="3">
                  <Form.Group>
                    <label
                      className="text-primary"
                      style={{ fontSize: 14, fontWeight: "600" }}
                    >
                      ITEM INFO
                    </label>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card>
                    <div className="">
                      <table className="table table-bordered mb-0">
                        <thead className="text-center">
                          <tr>
                            <th scope="col" style={{ color: "grey" }}>
                              ITEM DETAILS
                            </th>
                            <th scope="col" style={{ color: "grey" }}>
                              ITEM CODE
                            </th>
                            <th scope="col" style={{ color: "grey" }}>
                              HSN/SAC
                            </th>
                            <th scope="col" style={{ color: "grey" }}>
                              QUANTITY
                            </th>
                            <th scope="col" style={{ color: "grey" }}>
                              RATE
                            </th>
                            <th scope="col" style={{ color: "grey" }}>
                              MAKE
                            </th>
                            {discountCheck === "YES" && (
                              <th scope="col" style={{ color: "grey" }}>
                                DISCOUNT
                              </th>
                            )}{" "}
                            {bussinessRegistered === "YES" && (
                              <th scope="col" style={{ color: "grey" }}>
                                TAX
                              </th>
                            )}
                            <th scope="col" style={{ color: "grey" }}>
                              AMOUNT
                            </th>
                            <th scope="col" style={{ color: "grey" }}>
                              ACTION
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {userDetails.map((data, i) => {
                            const amount = calculateAmount(data, data.tax); // Calculate amount
                            const totalAmountWithTax = parseFloat(amount);
                            const itemDescription = itemDescriptions.find(
                              (desc) => desc.itemDetails === data.itemsDetails
                            );
                            return (
                              <tr key={i}>
                                <td style={{ width: "35%" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      className="form-control noBorder"
                                      placeholder="Search items..."
                                      value={data.itemsDetails}
                                      onChange={(e) =>
                                        handleItemDetailsChange(e, i)
                                      }
                                      style={{ flex: 1 }}
                                    />
                                    {data.itemsDetails && (
                                      <button
                                        type="button"
                                        className="btn p-0"
                                        onClick={() =>
                                          handleItemShow(data.itemsDetails)
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        <span
                                          className="text-primary"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Edit
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                  {itemDescription && (
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        color: "#6c757d",
                                        whiteSpace: "pre-line",
                                      }}
                                    >
                                      {itemDescription.description}
                                    </div>
                                  )}

                                  {searchPerformed &&
                                    data.itemsDetails &&
                                    i === searchIndex && (
                                      <div
                                        style={{
                                          position: "relative",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {searchResults.length === 0 && ""}
                                        {searchResults.length > 0 && (
                                          <ul
                                            style={{
                                              listStyleType: "none",
                                              padding: 0,
                                              position: "absolute",
                                              top: "calc(100% + 10px)",
                                              left: 0,
                                              zIndex: 1000,
                                              backgroundColor: "#FFF",
                                              boxShadow:
                                                "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                              borderRadius: "5px",
                                              width: "100%",
                                              maxHeight: "200px", // Set a max height for the list
                                              overflowY: "auto", // Enable vertical scrolling
                                            }}
                                          >
                                            {searchResults.map(
                                              (result, index) => (
                                                <li
                                                  key={index}
                                                  onClick={() =>
                                                    handleItemSelect(
                                                      result.name,
                                                      result.id,
                                                      i
                                                    )
                                                  }
                                                  onMouseEnter={(e) => {
                                                    e.target.style.backgroundColor =
                                                      "#408dfb";
                                                    e.target.style.color =
                                                      "#FFF";
                                                    e.target.style.borderRadius =
                                                      "5px"; // Apply border radius on hover
                                                  }}
                                                  onMouseLeave={(e) => {
                                                    e.target.style.backgroundColor =
                                                      "";
                                                    e.target.style.color =
                                                      "#000";
                                                    e.target.style.borderRadius =
                                                      ""; // Reset border radius
                                                  }}
                                                  style={{
                                                    padding: "12px",
                                                    cursor: "pointer",
                                                    whiteSpace: "nowrap", // Prevent text from overflowing
                                                    overflow: "hidden", // Hide overflow text
                                                    textOverflow: "ellipsis", // Add ellipsis for overflowing text
                                                  }}
                                                >
                                                  {result.name}
                                                  <br />
                                                  Rate : ₹{result.taxableAmount}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        )}
                                      </div>
                                    )}
                                </td>

                                <Modal
                                  show={showItemModal}
                                  onHide={handleItemClose}
                                >
                                  <div class="modal-header d-flex align-items-center pb-2 pt-2">
                                    <h5
                                      class="modal-title fs-5"
                                      id="salespersonHead"
                                    >
                                      Add Item Description
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn pr-0"
                                      onClick={handleItemClose}
                                    >
                                      <ion-icon
                                        name="close-circle-outline"
                                        size="large"
                                        style={{ width: "28px" }}
                                      ></ion-icon>
                                    </button>
                                  </div>
                                  <Modal.Body>
                                    <Row>
                                      <Col md="12">
                                        <div className="row pt-2">
                                          <div className="col-lg-6">
                                            <label
                                              className="form-label"
                                              style={{
                                                fontSize: "14px",
                                                color: "grey",
                                                borderRadius: "7px",
                                              }}
                                            >
                                              Item
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-lg-12 pl-0 pr-0">
                                          <input
                                            className="form-control"
                                            placeholder="Name"
                                            id="name"
                                            name="name"
                                            type="text"
                                            value={itemName}
                                            readOnly
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="12">
                                        <div className="row pt-2">
                                          <div className="col-lg-6">
                                            <label
                                              className="form-label"
                                              style={{
                                                fontSize: "14px",
                                                color: "grey",
                                                borderRadius: "7px",
                                              }}
                                            >
                                              Description
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-lg-12 pl-0 pr-0">
                                          <textarea
                                            className="cn-textarea"
                                            type="text"
                                            placeholder="Item Description"
                                            name="description"
                                            value={itemData.description}
                                            onChange={handleItemChange}
                                            rows={2}
                                            style={{
                                              backgroundColor: "#F6F6F6",
                                              borderRadius: "9px",
                                              padding: "12px",
                                              width: "100%",
                                            }}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Modal.Body>
                                  <Modal.Footer className="p-2">
                                    <div className="modal-bottombtns">
                                      <button
                                        type="button"
                                        class="modalbtn-1 btn mt-0"
                                        onClick={handleItemClose}
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="button"
                                        class="modalbtn-3 btn btn-primary"
                                        onClick={handleItem}
                                        data-bs-dismiss="modal"
                                      >
                                        Add Description
                                      </button>
                                    </div>
                                  </Modal.Footer>
                                </Modal>

                                <td style={{ width: "10%" }}>
                                  <input
                                    className="form-control noArrowNumber noBorder bg-white"
                                    value={data.itemCode || ""}
                                    type="text"
                                    name="itemCode"
                                    onChange={(e) => handleInputChangess(e, i)}
                                  />
                                </td>

                                <td style={{ width: "10%" }}>
                                  <input
                                    className="form-control noArrowNumber noBorder bg-white"
                                    value={data.hsnSac || ""}
                                    type="text"
                                    name="hsnSac"
                                    onChange={(e) => handleInputChangess(e, i)}
                                  />
                                </td>
                                <td style={{ width: "10%" }}>
                                  <input
                                    className="form-control noArrowNumber noBorder"
                                    value={data.quantity || ""}
                                    type="number"
                                    name="quantity"
                                    onChange={(e) => handleInputChangess(e, i)}
                                  />
                                </td>

                                <td style={{ width: "10%" }}>
                                  <input
                                    className="form-control noArrowNumber noBorder"
                                    value={data.rate}
                                    name="rate"
                                    type="number"
                                    onChange={(e) => handleInputChangess(e, i)}
                                  />
                                </td>

                                <td style={{ width: "10%" }}>
                                  <input
                                    className="form-control noArrowNumber noBorder bg-white"
                                    value={data.make || ""}
                                    type="text"
                                    name="make"
                                    onChange={(e) => handleInputChangess(e, i)}
                                  />
                                </td>

                                {discountCheck === "YES" && (
                                  <td style={{ width: "10%", padding: "0px" }}>
                                    <div className="d-flex justify-content-center">
                                      <input
                                        className="form-control col-md-6 noBorder"
                                        name="discount"
                                        value={data.discount || ""}
                                        type="number"
                                        onChange={(e) =>
                                          handleInputChangess(e, i)
                                        }
                                        style={{
                                          textAlign: "center",
                                          padding: "0px",
                                          marginRight: "5px",
                                        }}
                                      />
                                      <select
                                        className="border-0 noBorder"
                                        value={data.discountType}
                                        onChange={(e) =>
                                          handleInputChangess(e, i)
                                        }
                                        name="discountType"
                                        style={{
                                          textAlign: "center",
                                          padding: "0px",
                                          marginRight: "5px",
                                        }}
                                      >
                                        <option value="₹">₹</option>
                                        <option value="%">%</option>
                                      </select>
                                    </div>
                                  </td>
                                )}
                                {bussinessRegistered === "YES" && (
                                  <td style={{ width: "10%" }}>
                                    <select
                                      className="form-control"
                                      value={data.tax}
                                      onChange={(e) => handleTaxChange(e, i)}
                                    >
                                      <option value="0">N/A</option>
                                      <option value="5">5 GST [5%]</option>
                                      <option value="12">12 GST[12%]</option>
                                      <option value="18">18 GST [18%]</option>
                                      <option value="28">28 GST[28%]</option>
                                    </select>
                                  </td>
                                )}
                                <td style={{ width: "10%" }}>
                                  <span>{totalAmountWithTax.toFixed(2)}</span>
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: "7%" }}
                                >
                                  <button
                                    className="btn px-2 btn-light icon-border btn-sm"
                                    type="button"
                                    onClick={() => handleDeleteRow(i)}
                                  >
                                    <i className="fa fa-trash text-danger"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <button
                    className="btn addline-btn"
                    type="button"
                    onClick={handleAdd}
                  >
                    + Add New Row
                  </button>
                </Col>
              </Row>

              <div className="customer-grid-container">
                <div className="customer-flex-item pb-0 pl-0 d-flex flex-column-reverse">
                  <div className="row pt-3">
                    <div className="col-lg-6">
                      <label
                        className="form-label"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Customer Notes
                      </label>
                    </div>

                    <div className="col-lg-12">
                      <textarea
                        className="form-control cnotes-textarea"
                        placeholder="Customer Notes..."
                        name="customerNote"
                        value={
                          formData.customerNote || "Thank You For Business."
                        }
                        onChange={handleInputChange}
                        type="text"
                        rows={1}
                        style={{
                          backgroundColor: "#F6F6F6",
                          borderRadius: "9px",
                          padding: "12px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="customer-subtotal-box pb-0">
                  <div className="customer-flex-item">
                    <div className="row">
                      <div className="col-lg-8">
                        <label className="form-label">Sub Total</label>
                      </div>
                      <div className="col-lg-4 text-end pl-0">
                        <label className="form-label">
                          {calculateSubTotal()}
                        </label>
                      </div>
                    </div>

                    {discountCheck === "YES" && (
                      <div className="row d-flex align-items-baseline mt-1">
                        <div className="col-lg-5 pr-0">
                          <label>Discount</label>
                        </div>
                        <div className="col-lg-3 pl-0 d-flex">
                          <input
                            className="form-control"
                            value={disAllValue || "0"}
                            type="number"
                            name="discount"
                            onChange={handleDisAllValueChange}
                            style={{
                              height: "36px",
                              padding: "3px 8px",
                              backgroundColor: "#F6F6F6",
                            }}
                          />

                          <select
                            className="form-control ml-1 pl-0 rounded"
                            name="discountType"
                            value={discountType}
                            onChange={handleDiscountTypeChange}
                            style={{
                              height: "36px",
                              width: "50px",
                              paddin: "3px 8px",
                              backgroundColor: "#F6F6F6",
                            }}
                          >
                            <option disabled>Select</option>
                            <option value="₹">₹</option>
                            <option value="%">%</option>
                          </select>
                        </div>
                        <div className="col-lg-4 text-end pl-0">
                          <label className="form-label textColor-red">
                            {" "}
                            - {parseFloat(disValue).toFixed(2)}
                          </label>
                        </div>
                      </div>
                    )}

                    {bussinessRegistered === "YES" && (
                      <div className="row mt-2">
                        {userState.state !== customerState && (
                          <>
                            {calculateIGSTForTax().map((taxResult, index) => (
                              <React.Fragment key={index}>
                                <div className="col-lg-8">
                                  <label className="form-label required">
                                    IGST ({(taxResult.taxRate * 100).toFixed(0)}
                                    %)
                                  </label>
                                </div>
                                <div className="col-lg-4 text-end pl-0">
                                  <label className="form-label required">
                                    {taxResult.totalIGST.toFixed(2)}
                                  </label>
                                </div>
                              </React.Fragment>
                            ))}
                          </>
                        )}

                        {userState.state === customerState && (
                          <>
                            {Object.entries(calculateGroupedSGSTandCGST()).map(
                              ([taxRate, taxData], index) => (
                                <React.Fragment key={index}>
                                  <div className="col-lg-8">
                                    <label className="form-label required">
                                      CGST (
                                      {((parseFloat(taxRate) * 100) / 2)
                                        .toFixed(1)
                                        .replace(/\.0$/, "")}
                                      %)
                                    </label>
                                  </div>
                                  <div className="col-lg-4 text-end pl-0">
                                    <label className="form-label required">
                                      {taxData.totalCGST.toFixed(2)}
                                    </label>
                                  </div>
                                  <div className="col-lg-8 mt-2">
                                    <label className="form-label required">
                                      SGST (
                                      {((parseFloat(taxRate) * 100) / 2)
                                        .toFixed(1)
                                        .replace(/\.0$/, "")}
                                      %)
                                    </label>
                                  </div>
                                  <div className="col-lg-4 text-end pl-0 mt-2">
                                    <label className="form-label required">
                                      {taxData.totalSGST.toFixed(2)}
                                    </label>
                                  </div>
                                </React.Fragment>
                              )
                            )}
                          </>
                        )}
                      </div>
                    )}
                    <div className="row mt-3 mb-3">
                      <div className="col-lg-5 pr-0">
                        <input
                          className="form-control col-md-10"
                          type="text"
                          placeholder="Adjustment Label"
                          value={formData.adjustmentLabel}
                          onChange={handleInputChange}
                          name="adjustmentLabel"
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "10px",
                            color: "black",
                            "::placeholder": {
                              color:
                                "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                            },
                          }}
                        />
                      </div>
                      <div className="col-lg-5 pl-0">
                        <input
                          className="form-control col-md-10"
                          type="text"
                          placeholder="Adjustment Value"
                          name="adjustment"
                          value={formData.adjustment}
                          onChange={handleInputChange}
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "10px",
                            color: "black",
                            "::placeholder": {
                              color:
                                "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                            },
                          }}
                        />
                      </div>
                      <div className="col-lg-2 text-end pl-0">
                        <label className="form-label mt-2">
                          {oppositeValue}
                        </label>
                      </div>
                    </div>

                    <div className="row d-flex align-items-baseline mb-3">
                      <div className="col-lg-5 pr-0">
                        <label className="form-label">TCS</label>
                      </div>
                      <div className="col-lg-5 pl-0">
                        <Select
                          className="p-0 col-md-10"
                          options={tcs}
                          value={selectedTcsOption}
                          onChange={handleTcsOptionChange}
                          placeholder="Select TCS..."
                          styles={customStyles3}
                        />
                      </div>
                      <div className="col-lg-2 text-end pl-0">
                        <label className="form-label mt-2">
                          {discountAmountTcs || formData.value}
                        </label>
                      </div>
                    </div>

                    <hr />
                    <div className="my-3 d-flex justify-content-between">
                      <label className="form-label required ">
                        Total (in Rupees) :
                      </label>
                      <label className="form-label required">
                        {calculateTotal()}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* <hr className="mt-4" />
              <Row>
                <Col md="12">
                  <Form.Group>
                    <Editor
                      value={content2}
                      apiKey="4h8oo2lv4rl9gi28mb7hv90bwsf4k369lnf0gmxragfpofwj"
                      init={{
                        branding: false,
                        height: 300,
                        menubar: true,
                        plugins:
                          "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                        toolbar:
                          "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                        image_advtab: true,
                      }}
                      onEditorChange={handleEditorChange2} // Event to capture editor content
                    />
                  </Form.Group>
                </Col>
              </Row> */}

              <hr className="mt-4" />
              <Row>
                <Col md="12">
                  <div className="row pt-2">
                    <div className="col-lg-6">
                      <label
                        className="form-label"
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          borderRadius: "7px",
                        }}
                      >
                        Terms & Conditions
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-12 pl-0 pr-0">
                    {/* <Editor
                      value={content}
                      apiKey="4h8oo2lv4rl9gi28mb7hv90bwsf4k369lnf0gmxragfpofwj"
                      init={{
                        branding: false,
                        height: 500,
                        menubar: true,
                        plugins:
                          "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                        toolbar:
                          "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                        image_advtab: true,
                      }}
                      onEditorChange={handleEditorChange}
                    /> */}
                    <ReactQuill
                      onChange={handleEditorChange} // Function to update state
                      theme="snow" // Use "bubble" for a minimal UI
                      style={{ height: "300px", marginBottom: "50px" }}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
                          [{ font: [] }], // Font options
                          [{ size: [] }], // Font size
                          [{ color: [] }, { background: [] }], // Text color & background
                          ["bold", "italic", "underline", "strike"], // Basic text styles
                          [{ script: "sub" }, { script: "super" }], // Subscript & superscript
                          [{ align: [] }], // Text alignment
                          [{ list: "ordered" }, { list: "bullet" }], // Lists
                          [{ indent: "-1" }, { indent: "+1" }], // Indentation
                          ["blockquote", "code-block"], // Blockquote & code block
                          ["link", "image", "video"], // Media links
                          ["clean"], // Remove formatting
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "color",
                        "background",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "script",
                        "align",
                        "list",
                        "bullet",
                        "indent",
                        "blockquote",
                        "code-block",
                        "link",
                        "image",
                        "video",
                      ]}
                    />

                    <i className="text-muted">
                      This Terms and Condition will be displayed on the
                      quotation
                    </i>
                  </div>
                </Col>
              </Row>

              <div className="text-right invoice-bottombtns">
                <button
                  type="button"
                  className="btn-1 btn mt-0"
                  disabled={loading}
                  onClick={handleCancleClick}
                >
                  Cancel
                </button>
                {props.fetchedStatus === "Draft" && (
                  <button
                    type="button"
                    className="btn-1 btn mt-0"
                    disabled={loading}
                    onClick={insertDataIntoDatabaseAsDraft}
                  >
                    {isLoading2 ? "Saving..." : "Save as Draft"}
                  </button>
                )}
                <button
                  type="button"
                  className="btn-3 btn btn-primary"
                  onClick={insertDataIntoDatabase}
                >
                  {isLoading ? "Updating..." : "Update"}
                </button>
              </div>
              <div className="clearfix"></div>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
export default QuotesUpdate;
