import React, { useState, useEffect, useRef } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./header.css";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../../screens/Dashboard/CommonApi";
import "./Navbar.css";
import { AuthURL } from "../../screens/Dashboard/CommonApi";
import AddCircleIcon from "@mui/icons-material/AddCircle";

function Navbar({ navigation }) {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const [itemData, setItemData] = useState({
    organizationName: "",
    companyName: "",
    email: "",
  });
  const handleLogOut = () => {
    sessionStorage.removeItem("uniqueId1");
    sessionStorage.removeItem("jwtToken");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("refreshToken");
    localStorage.removeItem("uniqueId1");
    localStorage.removeItem("userId");
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("loginDate");
    handleNavigation("login");
  };

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const jwtToken = localStorage.getItem("jwtToken");
  const userId = localStorage.getItem("userId");
  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const portfolioItem = data.responseMessage;
          setItemData({
            organizationName: portfolioItem.organizationName,
            companyName: portfolioItem.companyName,
            name: portfolioItem.name,
            role: portfolioItem.role,
            email: portfolioItem.email,
            uniqueId: portfolioItem.uniqueId,
          });
        } else {
          console.error("Error fetching portfolio data");
          handleNavigation("login");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
        handleNavigation("login");
      });
  };
  const intervalIdRef = useRef(null);

  const fetchDataToken = async () => {
    const apiUrl = AuthURL + "/refreshToken";
    const refreshToken1 = localStorage.getItem("refreshToken");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken: refreshToken1 }),
      });

      if (!response.ok) {
        // handleLogOut()
        throw new Error("POST request failed");
      }

      const responseData = await response.json();
      const token = responseData.jwtToken;
      const refreshToken = responseData.refreshToken;

      localStorage.setItem("jwtToken", token);
      localStorage.setItem("refreshToken", refreshToken);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const startFetchingDataInterval = () => {
    const intervalId = setInterval(() => {
      fetchDataToken();
    }, 2 * 60 * 1000);
    return intervalId;
  };
  const checkLoginDate = () => {
    const loginDate = localStorage.getItem("loginDate");
    const currentDate = new Date();
    const formattedCurrentDate = `${String(currentDate.getDate()).padStart(
      2,
      "0"
    )}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${currentDate.getFullYear()}`;

    if (loginDate !== formattedCurrentDate) {
      window.location.reload();
      localStorage.setItem("loginDate", formattedCurrentDate);
    }
  };
  useEffect(() => {
    intervalIdRef.current = startFetchingDataInterval();
    return () => clearInterval(intervalIdRef.current);
  }, []);
  useEffect(() => {
    fetchData();
    checkLoginDate();
  }, []);
  let toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const titleCaseCompanyName = toTitleCase(itemData.companyName);
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleProfileClick = () => {
    handleNavigation("profile");
    setDropdownOpen(false);
  };

  const handleSettingClick = () => {
    handleNavigation("Setting");
  };

  const handleQuotationOpen = () => {
    handleNavigation("quotes");
  };
  const handleOpenNewINV = () => {
    handleNavigation("InvoicesNew");
  };

  const handleOpenNewBill = () => {
    handleNavigation("BillsNew");
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(e.target) &&
        !dropdownButtonRef.current.contains(e.target)
      ) {
        setDropdownOpen(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <>
      <nav className="navbar navbar-fixed-top" style={{ width: "100%" }}>
        <div
          className="container-fluid"
          style={{ marginLeft: "275px", maxWidth: "82%", zIndex: "99" }}
        >
          <div className="navbar-brand">
            <span
              style={{
                color: "#F3B50D",
                fontSize: 20,
                fontWeight: 600,
                wordWrap: "break-word",
              }}
            >
              Welcome, {titleCaseCompanyName}!
            </span>
          </div>

          <div className="navbar-right">
            <form id="navbar-search" className="navbar-form search-form">
              <button type="button" className="btn btn-default"></button>
            </form>

            <div id="navbar-menu mr-r-12">
              <ul
                className="nav navbar-nav "
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "10px",
                  padding: "10px",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <li className="mr-6" style={{ marginRight: "50px" }}>
                  <a
                    href="#"
                    className="icon-menu"
                    style={{ cursor: "pointer" }}
                    onClick={handleQuotationOpen}
                  >
                    <AddCircleIcon />
                    <span
                      style={{
                        color: "black",
                        fontSize: "16px",
                        marginRight: "6px",
                      }}
                    >
                      Quotation{" "}
                    </span>
                  </a>
                </li>
                <li className="mr-6" style={{ marginRight: "50px" }}>
                  <a
                    href="#"
                    className="icon-menu"
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenNewINV}
                  >
                    <AddCircleIcon />
                    <span
                      style={{
                        color: "black",
                        fontSize: "16px",
                        marginRight: "6px",
                      }}
                    >
                      Invoice{" "}
                    </span>
                  </a>
                </li>
                <li className="mr-4">
                  <a
                    href="#"
                    className="icon-menu"
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenNewBill}
                  >
                    <AddCircleIcon />
                    <span style={{ color: "black", fontSize: "16px" }}>
                      {" "}
                      Purchase
                    </span>
                  </a>
                </li>

                <li className="mr-2">
                  <a
                    href="#"
                    className="icon-menu"
                    style={{ cursor: "pointer" }}
                    onClick={handleSettingClick}
                  >
                    <SettingsIcon
                      className="icon-menu"
                      style={{ width: "30px", color: "black" }}
                    />{" "}
                  </a>
                </li>
                <li className="mr-1">
                  <a
                    href="#"
                    className="icon-menu"
                    style={{ cursor: "pointer" }}
                    onClick={handleProfileClick}
                  >
                    <AccountCircleIcon
                      className="icon-menu"
                      style={{ width: "30px", color: "black" }}
                    />{" "}
                  </a>
                </li>
                <li
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    paddingTop: "10px",
                  }}
                  className="icon-menu ml-2"
                >
                  <h6 className="mt-1">
                    {itemData.role}
                    <span>{/* <p>{itemData.email}</p> */}</span>
                  </h6>
                </li>

                <li
                  style={{
                    marginLeft: "10px",
                    cursor: "pointer",
                    position: "relative",
                    paddingTop: "10px",
                  }}
                  className="ml-4"
                >
                  <button
                    type="button"
                    style={{
                      border: "none",
                      outline: "none",
                      backgroundColor: "white",
                    }}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    ref={dropdownButtonRef}
                  >
                    <ArrowDropDownIcon style={{ cursor: "pointer" }} />
                    {dropdownOpen && (
                      <div
                        ref={dropdownMenuRef}
                        className="useroptions-dropdown"
                      >
                        <ul
                          style={{
                            listStyleType: "none",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <li
                            className="useroptions-option1 cursor-pointer"
                            onClick={handleProfileClick}
                          >
                            Profile
                          </li>
                          <li
                            className="useroptions-option2 cursor-pointer"
                            onClick={handleLogOut}
                          >
                            Log Out
                          </li>
                        </ul>
                      </div>
                    )}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
